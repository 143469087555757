<template>
  <div class="stageline-navigation-icon">
    <!-- STAGES -->
    <svg
      v-if="type === 'ny-publication-requirement' && iconStatus !== 'icon-completed'"
      id="ny-publication-requirement"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      xml:space="preserve"
      :width="width"
      :height="height"
    >
      <g>
        <g transform="matrix(1, 0, 0, 1, 1380, 655)">
          <g
            id="Ellipse_8-2_00000017483020541356324300000011506055373702365857_"
            transform="translate(9 6)"
          >
            <circle class="st0" cx="-1339" cy="-611" r="50" />
            <circle class="st1" cx="-1339" cy="-611" r="47" />
          </g>
        </g>
        <g id="Group_91" transform="translate(-1781 -796)">
          <g id="Group_37" transform="translate(805.782 493.191)">
            <path
              id="Rectangle_837"
              class="st2"
              d="M1000.2,327.8h41.5l0,0v50l0,0h-19.7c-12.1,0-21.9-9.8-21.9-21.9c0,0,0,0,0,0V327.8
                L1000.2,327.8z"
            />
            <g id="Group_67" transform="translate(1030.357 348.142)">
              <path id="Path_26" class="st3" d="M-5,1.7l3.1,3.5l4.4-7.1" />
              <ellipse id="Ellipse_10" class="st3" cx="-1.2" cy="1.7" rx="7.8" ry="7.7" />
            </g>
            <path
              id="Rectangle_838"
              class="st2"
              d="M1041.7,337.4h4.6l0,0v35.8c0,2.5-2,4.6-4.6,4.6l0,0l0,0V337.4L1041.7,337.4z"
            />
            <path id="Path_28" class="st2" d="M1046.3,337.4h3.9v34.9c0,3-2.5,5.5-5.5,5.5c0,0,0,0,0,0h-3" />
            <rect id="Rectangle_839" x="1005.1" y="331.5" class="st2" width="31.8" height="7.5" />
            <g id="Group_68" transform="translate(1014.078 349.202)">
              <line id="Line_9" class="st3" x1="-9" y1="-6" x2="3" y2="-6" />
              <line id="Line_10" class="st3" x1="-9" y1="-2.1" x2="3" y2="-2.1" />
              <line id="Line_11" class="st3" x1="-9" y1="1.9" x2="3" y2="1.9" />
              <line id="Line_12" class="st3" x1="-9" y1="5.8" x2="3" y2="5.8" />
              <line id="Line_13" class="st3" x1="-9" y1="9.8" x2="7.6" y2="9.8" />
              <line id="Line_14" class="st3" x1="-9" y1="17.7" x2="22.8" y2="17.7" />
              <line id="Line_15" class="st3" x1="-9" y1="13.7" x2="22.8" y2="13.7" />
              <line id="Line_16" class="st3" x1="-9" y1="21.6" x2="22.8" y2="21.6" />
            </g>
          </g>
        </g>
      </g>
    </svg>
    <svg
      v-if="type === 'bank_account' && iconStatus !== 'icon-completed'"
      id="bank_account"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      :width="width"
      :height="height"
    >
      <g id="Layer_1-2">
        <g id="Group_90">
          <g id="Ellipse_8-2">
            <circle class="st0" cx="50" cy="50" r="50" />
            <circle class="cls-4" cx="50" cy="50" r="47" />
          </g>
          <g id="Group_36">
            <g>
              <path
                class="cls-3"
                d="M70.6,65.2h-15c-1.69,.8-3.57,1.26-5.57,1.26s-3.88-.46-5.57-1.26h-15.07v3.9h41.2v-3.9Z"
              />
              <polygon
                class="cls-3"
                points="70.6 69.1 29.4 69.1 27 69.1 27 73 73.1 73 73.1 69.1 70.6 69.1"
              />
              <path
                class="cls-3"
                d="M60.33,61.4v3.76h5.5v-23.5h-5.5v3.76c1.72,2.21,2.75,4.98,2.75,7.99s-1.03,5.78-2.75,7.99Z"
              />
              <path
                class="cls-3"
                d="M39.73,45.42v-3.76h-5.5v23.5h5.5v-3.76c-1.72-2.21-2.75-4.98-2.75-7.99s1.03-5.78,2.75-7.99Z"
              />
              <path
                class="cls-3"
                d="M55.56,41.6h15.08v-3.9H29.43v3.9h15.08c1.68-.79,3.55-1.24,5.52-1.24s3.84,.45,5.52,1.24Z"
              />
              <path
                class="cls-3"
                d="M74.13,37.7l-24.1-14.7-24.2,14.7h48.3Zm-34.5-3.69l10.37-5.81,10.33,5.81h-20.7Z"
              />
              <polygon class="cls-3" points="39.63 34.01 60.33 34.01 50 28.2 39.63 34.01" />
              <path
                class="cls-3"
                d="M60.33,45.42c-1.26-1.62-2.9-2.94-4.78-3.82-1.68-.79-3.55-1.24-5.52-1.24s-3.84,
                  .45-5.52,1.24c-1.88,.88-3.52,2.19-4.78,3.82-1.72,2.21-2.75,4.98-2.75,7.99s1.03,
                  5.78,2.75,7.99c1.25,1.61,2.87,2.91,4.73,3.8,1.69,.8,3.57,1.26,5.57,1.26s3.88-.46,
                  5.57-1.26c1.86-.88,3.48-2.19,4.73-3.8,1.72-2.21,2.75-4.98,2.75-7.99s-1.03-5.78-2.75-7.99Z"
              />
              <path
                class="cls-2"
                d="M54.6,59.78c-.86,.8-2.11,1.26-3.75,1.39v2.46h-1.39v-2.46c-1.06-.06-2.06-.26-3.01-.6-.95-.34-1.71-.77-2.27-1.29l.8-1.7c.52,
                  .48,1.18,.87,1.98,1.18,.8,.31,1.63,.5,2.5,
                  .56v-5.04c-1.01-.25-1.86-.51-2.53-.8-.67-.29-1.24-.72-1.69-1.28s-.68-1.3-.68-2.22c0-1.14,
                  .42-2.11,1.25-2.91s2.05-1.26,3.66-1.39v-2.48h1.39v2.45c.83,.03,1.63,.16,2.4,
                  .39s1.44,.54,2.01,.93l-.72,1.74c-1.17-.74-2.4-1.14-3.69-1.22v5.06c1.06,.26,1.93,
                  .53,2.61,.81,.68,.28,1.26,.7,1.73,1.26,.47,.56,.71,1.3,.71,2.23,0,1.14-.43,
                  2.11-1.29,2.91Zm-7.2-8.43c.48,.35,1.17,.64,2.06,.87v-4.69c-.93,.12-1.62,.39-2.09,
                  .81s-.69,.94-.69,1.55,.24,1.11,.72,1.46Zm5.64,7.2c.49-.41,.73-.93,
                  .73-1.55s-.25-1.12-.75-1.46-1.22-.64-2.16-.9v4.67c.97-.09,1.7-.34,2.18-.75Z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
    <svg
      v-if="type === 'irs' && iconStatus !== 'icon-completed'"
      id="irs"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      xml:space="preserve"
      :width="width"
      :height="height"
    >
      <g id="Group_89" transform="translate(-979 -515)">
        <g transform="matrix(1, 0, 0, 1, 979, 515)">
          <g id="Ellipse_7-2" transform="translate(9 6)">
            <circle class="st0" cx="41" cy="44" r="50" />
            <circle class="st1" cx="41" cy="44" r="47" />
          </g>
        </g>
        <g id="Group_35" transform="translate(692.131 209.191)">
          <g id="Group_49" transform="translate(321.972 340.705)">
            <g id="Group_48" transform="translate(2.27)">
              <path
                id="Path_23" class="st3"
                d="M16.3-5.8c-2.4-0.3-4.9-0.3-7.3,0C-0.1-4.7-7.3,0.3-9,6.7h43.3C32.5,0.3,25.3-4.7,16.3-5.8z"
              />
            </g>
            <rect id="Rectangle_815" x="-9" y="6.7" class="st3" width="47.8" height="3.1" />
          </g>
          <g id="Group_58" transform="translate(324.502 356.487)">
            <g id="Group_51">
              <g id="Group_50">
                <rect id="Rectangle_816" x="-9" y="-6" class="st3" width="6.2" height="3.7" />
                <rect id="Rectangle_817" x="-9" y="10.7" class="st3" width="6.2" height="3.7" />
                <rect id="Rectangle_818" x="-8.3" y="-2.3" class="st3" width="4.7" height="13" />
              </g>
            </g>
            <g id="Group_53" transform="translate(12.194)">
              <g id="Group_52">
                <rect id="Rectangle_819" x="-9" y="-6" class="st3" width="6.2" height="3.7" />
                <rect id="Rectangle_820" x="-9" y="10.7" class="st3" width="6.2" height="3.7" />
                <rect id="Rectangle_821" x="-8.3" y="-2.3" class="st3" width="4.7" height="13" />
              </g>
            </g>
            <g id="Group_55" transform="translate(24.388)">
              <g id="Group_54">
                <rect id="Rectangle_822" x="-9" y="-6" class="st3" width="6.2" height="3.7" />
                <rect id="Rectangle_823" x="-9" y="10.7" class="st3" width="6.2" height="3.7" />
                <rect id="Rectangle_824" x="-8.3" y="-2.3" class="st3" width="4.7" height="13" />
              </g>
            </g>
            <g id="Group_57" transform="translate(36.582)">
              <g id="Group_56">
                <rect id="Rectangle_825" x="-9" y="-6" class="st3" width="6.2" height="3.7" />
                <rect id="Rectangle_826" x="-9" y="10.7" class="st3" width="6.2" height="3.7" />
                <rect id="Rectangle_827" x="-8.3" y="-2.3" class="st3" width="4.7" height="13" />
              </g>
            </g>
          </g>
          <g id="Group_59" transform="translate(320.869 376.88)">
            <rect id="Rectangle_828" x="-6.4" y="-6" class="st3" width="44.7" height="3.5" />
            <rect id="Rectangle_829" x="-9" y="-2.5" class="st3" width="50" height="3.5" />
          </g>
          <g id="Group_60" transform="translate(345.869 333.809)">
            <rect id="Rectangle_830" x="-9" y="-5.4" class="st3" width="6.5" height="3.8" />
            <line id="Line_7" class="st3" x1="-9" y1="-6" x2="-9" y2="0.9" />
          </g>
        </g>
      </g>
    </svg>
    <svg
      v-if="type === 'finalize_legal_documents' && iconStatus !== 'icon-completed'"
      id="finalize_legal_documents"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      xml:space="preserve"
      :width="width"
      :height="height"
    >
      <g id="Group_93" transform="translate(-578 -375)">
        <g transform="matrix(1, 0, 0, 1, 578, 375)">
          <g id="Ellipse_6-2" transform="translate(9 6)">
            <circle class="st0" cx="41" cy="44" r="50" />
            <circle class="st1" cx="41" cy="44" r="47" />
          </g>
        </g>
        <g id="Group_92" transform="translate(660 72.191)">
          <path
            id="Path_29"
            class="st3"
            d="M-55.2,342.6H-59v2.4c-0.1,2.2,1.6,4.1,3.8,4.2l0,0c2.2-0.1,3.9-2,3.8-4.2v-12.6
              c-0.1-2.4,1.7-4.5,4.2-4.7l0,0c2.4,0.2,4.3,2.2,4.2,4.7v45.3h30v-10.3"
          />
          <path id="Path_30" class="st3" d="M-47.3,327.8h30.7c2,0.1,3.5,1.9,3.4,3.9v5.8" />
          <line id="Line_17" class="st3" x1="-55.2" y1="349.3" x2="-43.1" y2="349.3" />
          <path
            id="Path_31"
            class="st3"
            d="M-37.9,366.5c0,0,6.2-1.4,6.2-3.8s-5.2-4.4-5.2-4.4l4.5,9.1c0,0,3.3-2.4,5.6-2.4"
          />
          <line id="Line_18" class="st3" x1="-37.9" y1="372.3" x2="-25.4" y2="372.3" />
          <line id="Line_19" class="st3" x1="-31.8" y1="333.3" x2="-23.1" y2="333.3" />
          <line id="Line_20" class="st3" x1="-37.9" y1="338.3" x2="-16.9" y2="338.3" />
          <line id="Line_21" class="st3" x1="-37.9" y1="343.2" x2="-16.9" y2="343.2" />
          <line id="Line_22" class="st3" x1="-37.9" y1="348.2" x2="-18.9" y2="348.2" />
          <line id="Line_23" class="st3" x1="-37.9" y1="353.2" x2="-21.5" y2="353.2" />
          <g id="Group_70" transform="translate(-12.456 346.109)">
            <path
              id="Path_32"
              class="st3"
              d="M-7.8,23L-7.8,23c-1-0.5-1.4-1.7-1-2.8l0.6-1.7l7.9-21.6l3.5,1.6L1.4,3.5l-6.7,18.4
                c-0.3,1-1.3,1.5-2.3,1.2C-7.7,23.1-7.8,23-7.8,23z"
            />
            <line id="Line_24" class="st3" x1="-4.7" y1="20.1" x2="-8.2" y2="18.5" />
            <line id="Line_25" class="st3" x1="-7.8" y1="23" x2="-8.5" y2="24.9" />
            <path id="Path_33" class="st3" d="M1.4,3.5L1.4,3.5c1.2,0.6,1.7,2.1,1.2,3.3L0.3,13" />
            <path
              id="Path_34"
              class="st3"
              d="M0.4-2.7l0.9-2.5c0.2-0.6,0.8-0.9,1.4-0.7c0,0,0.1,0,0.1,0l0,0c0.6,0.3,0.9,1,0.6,1.7L2.5-1.8"
            />
          </g>
        </g>
      </g>
    </svg>
    <svg
      v-if="type === 'form_the_company' && iconStatus !== 'icon-completed'"
      id="form_the_company"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      xml:space="preserve"
      :width="width"
      :height="height"
    >
      <g id="Group_88" transform="translate(-177 -235)">
        <g transform="matrix(1, 0, 0, 1, 177, 235)">
          <g id="Ellipse_5-2" transform="translate(9 6)">
            <circle class="st0" cx="41" cy="44" r="50" />
            <circle class="st1" cx="41" cy="44" r="47" />
          </g>
        </g>
        <g id="Group_103" transform="translate(509.31 -107.099)">
          <g id="Group_113">
            <g id="Group_103-2">
              <rect id="Rectangle_841" x="-279.1" y="379.1" class="st4" width="21.8" height="38" />
            </g>
            <g id="Group_112">
              <g id="Group_104">
                <rect id="Rectangle_842" x="-275.8" y="382.6" class="st5" width="2.6" height="2.2" />
                <rect id="Rectangle_843" x="-271.6" y="382.6" class="st5" width="2.6" height="2.2" />
                <rect id="Rectangle_844" x="-267.4" y="382.6" class="st5" width="2.6" height="2.2" />
                <rect id="Rectangle_845" x="-263.2" y="382.6" class="st5" width="2.6" height="2.2" />
              </g>
              <g id="Group_105">
                <rect id="Rectangle_846" x="-275.8" y="386.7" class="st5" width="2.6" height="2.2" />
                <rect id="Rectangle_847" x="-271.6" y="386.7" class="st5" width="2.6" height="2.2" />
                <rect id="Rectangle_848" x="-267.4" y="386.7" class="st5" width="2.6" height="2.2" />
                <rect id="Rectangle_849" x="-263.2" y="386.7" class="st5" width="2.6" height="2.2" />
              </g>
              <g id="Group_106">
                <rect id="Rectangle_850" x="-275.8" y="390.8" class="st5" width="2.6" height="2.2" />
                <rect id="Rectangle_851" x="-271.6" y="390.8" class="st5" width="2.6" height="2.2" />
                <rect id="Rectangle_852" x="-267.4" y="390.8" class="st5" width="2.6" height="2.2" />
                <rect id="Rectangle_853" x="-263.2" y="390.8" class="st5" width="2.6" height="2.2" />
              </g>
              <g id="Group_107">
                <rect id="Rectangle_854" x="-275.8" y="395" class="st5" width="2.6" height="2.2" />
                <rect id="Rectangle_855" x="-267.4" y="395" class="st5" width="2.6" height="2.2" />
                <rect id="Rectangle_856" x="-263.2" y="395" class="st5" width="2.6" height="2.2" />
              </g>
              <g id="Group_108">
                <rect id="Rectangle_857" x="-275.8" y="399.1" class="st5" width="2.6" height="2.2" />
                <rect id="Rectangle_858" x="-271.6" y="399.1" class="st5" width="2.6" height="2.2" />
                <rect id="Rectangle_859" x="-267.4" y="399.1" class="st5" width="2.6" height="2.2" />
                <rect id="Rectangle_860" x="-263.2" y="399.1" class="st5" width="2.6" height="2.2" />
              </g>
              <g id="Group_109">
                <rect id="Rectangle_861" x="-275.8" y="403.2" class="st5" width="2.6" height="2.2" />
                <rect id="Rectangle_862" x="-271.6" y="403.2" class="st5" width="2.6" height="2.2" />
                <rect id="Rectangle_863" x="-267.4" y="403.2" class="st5" width="2.6" height="2.2" />
                <rect id="Rectangle_864" x="-263.2" y="403.2" class="st5" width="2.6" height="2.2" />
              </g>
              <g id="Group_110">
                <rect id="Rectangle_865" x="-275.8" y="407.3" class="st5" width="2.6" height="2.2" />
                <rect id="Rectangle_866" x="-271.6" y="407.3" class="st5" width="2.6" height="2.2" />
                <rect id="Rectangle_867" x="-267.4" y="407.3" class="st5" width="2.6" height="2.2" />
                <rect id="Rectangle_868" x="-263.2" y="407.3" class="st5" width="2.6" height="2.2" />
              </g>
              <g id="Group_111">
                <rect id="Rectangle_869" x="-275.8" y="411.4" class="st5" width="2.6" height="2.2" />
                <rect id="Rectangle_870" x="-271.6" y="411.4" class="st5" width="2.6" height="2.2" />
                <rect id="Rectangle_871" x="-267.4" y="411.4" class="st5" width="2.6" height="2.2" />
                <rect id="Rectangle_872" x="-263.2" y="411.4" class="st5" width="2.6" height="2.2" />
              </g>
            </g>
          </g>
          <g id="Group_115">
            <g id="Group_114">
              <path
                id="Path_62"
                class="st4"
                d="M-281.6,375.9c0-5.1-4.6-9.1-10.1-8.8c-4.5,0.2-8.3,3.6-8.8,8.1c-0.2,2.1,0.5,4.1,1.7,5.8
                  c1,1.7,1.8,3.5,2.4,5.3h10.5c0.6-1.9,1.4-3.7,2.5-5.4C-282.2,379.5-281.6,377.7-281.6,375.9z"
              />
              <path
                id="Path_63"
                class="st4"
                d="M-289.2,376.6c0.6-0.5,1-1.2,1-2c-0.1-1.6-1.4-2.8-2.9-2.7c-1.6-0.1-2.9,1.2-2.9,2.7
                  c0,0.9,0.4,1.7,1.2,2.2l-1.2,4.9h6.1L-289.2,376.6z"
              />
            </g>
          </g>
          <g id="Group_118">
            <g id="Group_117">
              <g id="Group_116">
                <path id="Path_64" class="st4" d="M-296.7,389.4v6.2h3.1v-3.1h12.1v3.1h3.1v-6.2L-296.7,389.4z" />
              </g>
              <path
                id="Path_65"
                class="st4"
                d="M-271.9,395.5h-31.2c-2.3,0-4.2,1.4-4.2,3.1V414c0,1.7,1.9,3.1,4.2,3.1h31.2
                  c2.3,0,4.2-1.4,4.2-3.1v-15.4C-267.8,396.9-269.6,395.5-271.9,395.5z"
              />
            </g>
            <path
              id="Path_66"
              class="st4"
              d="M-307.3,401.3l3.5,2.3c1.6,1.1,3.5,1.6,5.4,1.6h21.7c1.9,0,3.8-0.5,5.4-1.6l3.5-2.3"
            />
            <rect id="Rectangle_873" x="-293.9" y="403.3" class="st4" width="12.8" height="3.9" />
          </g>
          <g id="Group_119">
            <line id="Line_39" class="st3" x1="-306.3" y1="387.4" x2="-301.6" y2="387.4" />
            <line id="Line_40" class="st3" x1="-304" y1="385.1" x2="-304" y2="389.8" />
          </g>
          <g id="Group_120">
            <line id="Line_41" class="st3" x1="-279.1" y1="370.4" x2="-274.4" y2="370.4" />
            <line id="Line_42" class="st3" x1="-276.7" y1="368" x2="-276.7" y2="372.7" />
          </g>
        </g>
      </g>
    </svg>
    <svg
      v-if="iconStatus === 'icon-completed'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      :width="width"
      :height="height"
    >
      <g id="Group_1725" data-name="Group 1725" transform="translate(-190 -629)">
        <g
          id="Ellipse_5"
          class="teals"
          data-name="Ellipse 5"
          transform="translate(190 629)"
          stroke-width="2"
        >
          <circle cx="50" cy="50" r="50" stroke="none" />
          <circle cx="50" cy="50" r="49" fill="none" />
        </g>
        <path
          id="Path_2"
          data-name="Path 2" d="M216.461,675.571l16.282,16.336,31.486-30.715"
          transform="translate(0.039 2.308)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="bevel"
          stroke-width="6"
        />

      </g>
    </svg>
    <svg
      v-if="type === 'business_idea_and_plan' && iconStatus !== 'icon-completed'"
      id="business_idea_and_plan"
      xmlns="http://www.w3.org/2000/svg"
      class="icons-v2"
      viewBox="0 0 100 100"
      :width="width"
      :height="height"
    >
      <g id="Layer_1-2">
        <g id="Ellipse_8-2">
          <circle class="cls-1" cx="50" cy="50" r="50" />
          <circle class="cls-3" cx="50" cy="50" r="47" />
        </g>
        <g>
          <g>
            <path
              class="cls-2"
              d="M60.89,41.52c.98-.88,1.61-2.15,1.61-3.57,0-2.65-2.15-4.79-4.79-4.79s-4.79,
                2.15-4.79,4.79c0,1.57,.76,2.95,1.92,3.83l-1.92,8.57h9.96l-1.98-8.83Z"
            />
            <path
              class="cls-2"
              d="M57.4,28.06c-7.06,.24-12.81,5.69-13.51,12.62-.05,.47-.07,.95-.07,1.43"
            />
            <path
              class="cls-2"
              d="M75,42.11c0-9.85-8.33-17.76-18.33-17.07-8.43,.58-15.26,7.4-15.86,15.83-.29,4.19,
                .92,8.09,3.16,11.2,2,2.78,4.15,4.41,4.15,9.25l.23,1.13h19.05l.19-.93c0-4.87,
                2.21-6.66,4.25-9.53s3.15-6.2,3.15-9.89Z"
            />
            <g>
              <rect
                class="cls-2"
                x="50.04"
                y="62.46"
                width="15.69"
                height="3.15"
                rx="1.54"
                ry="1.54"
              />
              <rect
                class="cls-2"
                x="50.04"
                y="65.59"
                width="15.69"
                height="3.15"
                rx="1.54"
                ry="1.54"
              />
              <rect
                class="cls-2"
                x="50.04"
                y="68.72"
                width="15.69"
                height="3.15"
                rx="1.54"
                ry="1.54"
              />
            </g>
            <line class="cls-2" x1="53.19" y1="75" x2="62.59" y2="75" />
          </g>
          <line class="cls-2" x1="34.38" y1="25" x2="34.38" y2="58.41" />
          <line class="cls-2" x1="31.25" y1="43.42" x2="31.25" y2="52.96" />
          <path
            class="cls-2"
            d="M43.77,62.47c0-3.02-2.45-5.46-5.46-5.46-1.54,0-2.92,.64-3.92,
              1.66-.99-1.02-2.38-1.66-3.92-1.66-3.02,0-5.46,2.45-5.46,5.46,0,2.58,1.8,4.74,4.21,
              5.31-.18,.55-.29,1.13-.29,1.74,0,3.02,2.45,5.46,5.46,5.46s5.46-2.45,
              5.46-5.46c0-.61-.1-1.19-.29-1.74,2.41-.57,4.21-2.73,4.21-5.31Zm-15.41,0c0-1.16,
              .94-2.11,2.11-2.11s2.11,.94,2.11,2.11-.94,2.11-2.11,2.11-2.11-.94-2.11-2.11Zm6.03,
              9.16c-1.16,0-2.11-.94-2.11-2.11s.94-2.11,2.11-2.11,2.11,.94,2.11,2.11-.94,2.11-2.11,
              2.11Zm3.92-7.05c-1.16,0-2.11-.94-2.11-2.11s.94-2.11,2.11-2.11,2.11,.94,2.11,2.11-.94,
              2.11-2.11,2.11Z"
          />
          <polygon
            class="cls-2"
            points="25 28.01 25 31.81 30.27 31.81 30.27 35.62 25 35.62 25 39.42 34.38 39.42 34.38
              35.62 34.38 31.81 34.38 28.01 25 28.01"
          />
        </g>
      </g>
    </svg>
    <svg
      v-if="type === 'business_contact_info' && iconStatus !== 'icon-completed'"
      id="business_contact_info"
      xmlns="http://www.w3.org/2000/svg"
      class="icons-v2"
      viewBox="0 0 100 100"
      :width="width"
      :height="height"
    >
      <g id="Layer_1-2">
        <g id="Ellipse_7-2">
          <circle class="cls-1" cx="50" cy="50" r="50" />
          <circle class="cls-3" cx="50" cy="50" r="47" />
        </g>
        <g>
          <g>
            <g>
              <polygon
                class="cls-2"
                points="53.03 42.19 53.87 41.53 54.71 40.88 64.85 32.94 75 25 53.21 25 31.41 25 41.4
                  32.94 51.38 40.88 52.2 41.53 53.03 42.19"
              />
              <polygon
                class="cls-2"
                points="51.38 40.88 52.2 41.53 53.03 42.19 53.87 41.53 54.71 40.88 53.87 41.53 53.03
                  42.19 52.2 41.53 51.38 40.88"
              />
              <polyline
                class="cls-2"
                points="41.4 48.82 51.38 40.88 41.4 32.94 31.41 25 31.41 29.67"
              />
              <polygon
                class="cls-2"
                points="54.71 40.88 64.85 48.82 75 56.75 75 40.88 75 25 64.85 32.94 54.71 40.88"
              />
              <polyline
                class="cls-2"
                points="39.76 56.75 53.21 56.75 75 56.75 64.85 48.82 54.71 40.88 53.87 41.53 53.03
                  42.19 52.2 41.53 51.38 40.88 38.24 51.33"
              />
            </g>
            <path
              class="cls-2"
              d="M36.92,42.53l-3.5-8.13c-.47-1.08-1.56-1.61-2.44-1.17l-3.68,1.8-.68,.34c-.81,
                .4-1.35,1.25-1.46,2.29h0c-1.24,14.08,5.04,28.65,15.86,36.81h0c.81,.59,1.77,.7,
                2.58,.3l.68-.34,3.68-1.8c.88-.43,1.22-1.66,
                .75-2.74l-3.5-8.13c-.47-1.08-1.56-1.61-2.44-1.17l-2.08,1.02c-.88,
                .43-1.97-.09-2.44-1.17l-4.91-11.39c-.47-1.08-.13-2.31,.75-2.74l2.08-1.02c.88-.43,
                1.22-1.66,.75-2.74Z"
            />
          </g>
          <path
            class="cls-4"
            d="M49.67,63.6c1.28,0,1.28-1.98,0-1.98s-1.28,1.98,0,1.98h0Z"
          />
          <path
            class="cls-4"
            d="M53.89,63.6c1.28,0,1.28-1.98,0-1.98s-1.28,1.98,0,1.98h0Z"
          />
          <path
            class="cls-4"
            d="M58.11,63.6c1.28,0,1.28-1.98,0-1.98s-1.28,1.98,0,1.98h0Z"
          />
          <path
            class="cls-4"
            d="M62.33,63.6c1.28,0,1.28-1.98,0-1.98s-1.28,1.98,0,1.98h0Z"
          />
          <path
            class="cls-4"
            d="M66.56,63.6c1.28,0,1.28-1.98,0-1.98s-1.28,1.98,0,1.98h0Z"
          />
          <path
            class="cls-4"
            d="M70.78,63.6c1.28,0,1.28-1.98,0-1.98s-1.28,1.98,0,1.98h0Z"
          />
          <path
            class="cls-4"
            d="M75,63.6c1.28,0,1.28-1.98,0-1.98s-1.28,1.98,0,1.98h0Z"
          />
        </g>
      </g>
    </svg>
    <svg
      v-if="type === 'fund_your_business' && iconStatus !== 'icon-completed'"
      id="fund_your_business"
      xmlns="http://www.w3.org/2000/svg"
      class="icons-v2"
      viewBox="0 0 100 100"
      :width="width"
      :height="height"
    >
      <g id="Layer_1-2">
        <g id="Ellipse_8-2">
          <circle class="cls-1" cx="50" cy="50" r="50" />
          <circle class="cls-3" cx="50" cy="50" r="47" />
        </g>
        <g>
          <g>
            <polyline
              class="cls-2"
              points="25 48.54 31.07 49.53 37.13 50.52 37.24 62.76 37.35 75 32.19 74.75 27.04 74.51"
            />
            <polyline
              class="cls-2"
              points="31.51 61.55 31.67 66.05 31.83 70.54"
            />
          </g>
          <path
            class="cls-2"
            d="M37.25,53.18c1.65,0,3.36-2.23,6.41-1.84l3.89,.51,3.89,.51c.69,.09,1.32,.39,
              1.82,.84s.88,1.05,1.07,1.74h0c.3,1.13,.04,2.26-.58,3.11s-1.6,1.41-2.74,
              1.41h-3.47s-3.47,0-3.47,0"
          />
          <path
            class="cls-2"
            d="M38.05,71.16h6.75c2.93,0,5.82-.44,8.6-1.29s5.44-2.11,7.9-3.75l3.42-2.29,
              3.42-2.29c1.28-.86,2.45-1.86,3.49-3s1.95-2.4,2.71-3.76h0c.57-1.02,.76-2.18,
              .6-3.28s-.66-2.15-1.49-2.96h0"
          />
          <path
            class="cls-2"
            d="M57.76,58.89c2.26,0,4.42-.47,6.38-1.33s3.73-2.1,5.21-3.63,2.68-3.36,
              3.51-5.39c.83-2.03,1.29-4.26,
                1.29-6.6s-.46-4.57-1.29-6.6-2.03-3.85-3.51-5.39-3.25-2.77-5.21-3.63-4.12-1.33-6.38-1.33-4.42,
                .47-6.38,1.33-3.73,2.1-5.21,3.63-2.68,3.36-3.51,5.39-1.29,4.26-1.29,6.6,.46,4.57,1.29,
                6.6"
          />
          <path
            class="cls-2"
            d="M57.76,54.76c3.42,0,6.52-1.43,8.77-3.75s3.63-5.52,3.63-9.06"
          />
          <path
            class="cls-2"
            d="M57.76,29.13c-3.42,0-6.52,1.43-8.77,3.75-2.24,2.32-3.63,5.52-3.63,9.06"
          />
          <g>
            <g>
              <path
                class="cls-2"
                d="M61.66,37.91c0-1.11-.44-2.12-1.14-2.85s-1.68-1.18-2.76-1.18-2.05,.45-2.76,
                  1.18-1.14,1.74-1.14,2.85,.44,2.12,1.14,2.85,1.68,1.18,2.76,1.18"
              />
              <path
                class="cls-2"
                d="M53.86,45.98c0,1.11,.44,2.12,1.14,2.85s1.68,1.18,2.76,1.18,2.05-.45,2.76-1.18,
                  1.14-1.74,1.14-2.85-.44-2.12-1.14-2.85-1.68-1.18-2.76-1.18"
              />
            </g>
            <polyline
              class="cls-2"
              points="57.76 32.1 57.76 41.95 57.76 51.79"
            />
          </g>
        </g>
      </g>
    </svg>
    <svg
      v-if="type === 'online_presence' && iconStatus !== 'icon-completed'"
      id="online_presence"
      xmlns="http://www.w3.org/2000/svg"
      :class="requiresAction ? 'required-action' : 'icons-v2'"
      viewBox="0 0 100 100"
      :width="width"
      :height="height"
    >
      <g id="Layer_1-2">
        <g id="Ellipse_8-2">
          <circle class="cls-1" cx="50" cy="50" r="50" />
          <circle class="cls-3" cx="50" cy="50" r="47" />
        </g>
        <g>
          <line class="cls-2" x1="42.25" y1="69.35" x2="57.68" y2="69.35" />
          <polyline
            class="cls-2"
            points="42.09 69.35 38.6 75 61.02 75 57.52 69.35"
          />
          <line class="cls-2" x1="25.1" y1="62.66" x2="74.9" y2="62.66" />
          <g>
            <path
              class="cls-2"
              d="M72.79,38.7h-6.03v17.35c0,1.05-.84,1.9-1.87,1.9h-29.79c-1.03,
                0-1.87-.85-1.87-1.9v-17.35h-6.03c-1.22,0-2.21,1-2.21,2.24v26.18c0,1.23,.99,2.24,
                2.21,2.24h45.59c1.22,0,2.21-1,2.21-2.24v-26.18c0-1.23-.99-2.24-2.21-2.24Z"
            />
            <path
              class="cls-2"
              d="M64.89,25h-29.79c-1.03,0-1.87,.85-1.87,1.9v29.15c0,1.05,.84,1.9,1.87,
                1.9h29.79c1.03,0,1.87-.85,1.87-1.9V26.9c0-1.05-.84-1.9-1.87-1.9Z"
            />
            <g>
              <polygon
                class="cls-2"
                points="48.7 34.07 36.57 34.07 36.57 39.02 36.57 45.98 48.7 45.98 48.7 39.02 48.7 34.07"
              />
              <polygon
                class="cls-2"
                points="63.43 34.07 51.3 34.07 51.3 39.02 51.3 45.98 63.43 45.98 63.43 39.02 63.43 34.07"
              />
            </g>
            <line class="cls-2" x1="36.57" y1="34.07" x2="48.7" y2="45.98" />
            <line class="cls-2" x1="48.7" y1="34.07" x2="36.57" y2="45.98" />
            <line class="cls-2" x1="51.3" y1="34.07" x2="63.43" y2="45.98" />
            <line class="cls-2" x1="63.43" y1="34.07" x2="51.3" y2="45.98" />
          </g>
          <line class="cls-2" x1="33.23" y1="31.04" x2="66.77" y2="31.04" />
          <line class="cls-2" x1="36.57" y1="49.33" x2="48.7" y2="49.33" />
          <line class="cls-2" x1="36.57" y1="52.06" x2="48.7" y2="52.06" />
          <line class="cls-2" x1="36.57" y1="54.8" x2="48.7" y2="54.8" />
          <line class="cls-2" x1="51.3" y1="49.33" x2="63.43" y2="49.33" />
          <line class="cls-2" x1="51.3" y1="52.06" x2="63.43" y2="52.06" />
          <line class="cls-2" x1="51.3" y1="54.8" x2="63.43" y2="54.8" />
          <path class="cls-5" d="M36.35,28.99c1.28,0,1.28-1.98,0-1.98s-1.28,1.98,0,1.98h0Z" />
          <path class="cls-5" d="M38.94,28.99c1.28,0,1.28-1.98,0-1.98s-1.28,1.98,0,1.98h0Z" />
          <path class="cls-5" d="M41.53,28.99c1.28,0,1.28-1.98,0-1.98s-1.28,1.98,0,1.98h0Z" />
        </g>
      </g>
    </svg>
    <svg
      v-if="type === 'trademarks' && iconStatus !== 'icon-completed'"
      id="trademarks"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      :width="width"
      :height="height"
    >
      <g id="Layer_1-2">
        <g id="Ellipse_8-2">
          <circle class="st0" cx="50" cy="50" r="50" />
          <circle class="cls-4" cx="50" cy="50" r="47" />
        </g>
        <g>
          <path
            class="cls-3"
            d="m75,33.4c-8.54-1.54-16.16-4.44-25-8.35-8.84,3.91-16.46,6.81-25,8.35,1.74,24.76,9.36,
            37.73,25,41.65,15.64-3.92,23.26-16.89,25-41.65h0Z"
          />
          <g>
            <g>
              <path
                class="cls-3"
                d="m49.98,30.02c-3.32,0-6.34,1.29-8.61,3.38-2.53,2.34-4.11,5.69-4.1,9.42,0,
                4.42,2.26,8.32,5.67,10.6.77.52,1.28,1.42,1.28,2.42v1.63c0,.7.27,1.35.7,1.84.42.48,
                1.01.82,1.67.92.14.02,6.46.02,6.46.02.02,0,.03,0,.05,0,.82-.02,1.55-.39,
                2.05-.96.43-.49.69-1.14.69-1.84v-1.63c0-1,.5-1.89,1.27-2.41h0c1.31-.9,2.45-2.02,
                3.36-3.33,0,0,0,0,0,0,1.44-2.07,2.28-4.59,2.27-7.31-.01-7.05-5.73-12.76-12.76-12.75Z"
              />
              <path
                class="cls-3"
                d="m54.16,60.4h0c-.21-.09-.44-.14-.68-.14h-6.88c-.24,
                0-.47.05-.68.15h0c-.6.27-.98.87-.98,1.53v2.75c0,1.73.97,3.24,
                2.39,4.02.66.36,1.41.57,2.21.57h1.06c.8,0,1.55-.21,2.21-.58,1.41-.79,2.37-2.3,
                2.37-4.03v-2.74c0-.66-.39-1.25-.99-1.52Z"
              />
              <polygon
                class="cls-5"
                points="43.83 47.4 43.83 47.4 43.83 47.4 43.83 47.4"
              />
              <g>
                <g>
                  <line class="cls-3" x1="46.73" y1="46.92" x2="46.73" y2="39.06" />
                  <line class="cls-3" x1="50.48" y1="44.01" x2="52.81" y2="46.92" />
                  <path
                    class="cls-3"
                    d="m46.73,39.06h4.06c1.37,0,2.48,1.11,2.48,2.48h0c0,1.37-1.11,2.48-2.48,
                    2.48h-4.06v-4.95h0Z"
                  />
                </g>
                <circle class="cls-3" cx="50" cy="42.99" r="8.12" />
              </g>
            </g>
            <line class="cls-3" x1="44.92" y1="64.21" x2="54.97" y2="62.09" />
            <line class="cls-3" x1="46.23" y1="66.9" x2="54.99" y2="65.05" />
          </g>
          <path
            class="cls-3"
            d="m28.4,35.9c.16,1.46.33,2.87.54,4.23s.43,2.69.68,3.96.53,2.51.83,3.7.63,2.34.98,
            3.44.73,2.16,1.13,3.18.83,2,1.29,2.93.94,1.83,1.45,2.68,1.05,1.67,1.61,2.44,1.16,1.51,
            1.78,2.2,1.27,1.35,1.95,1.96,1.38,1.19,2.12,1.73"
          />
          <path
            class="cls-3"
            d="m71.41,36.09c-.53-.11-1.05-.22-1.57-.34s-1.04-.24-1.56-.37-1.03-.26-1.54-.4-1.03-.28-1.54-.43-1.02-.3-1.53-.46"
          />
        </g>
      </g>
    </svg>
    <svg
      v-if="type === 'get_dba' && iconStatus !== 'icon-completed'"
      id="get_dba"
      xmlns="http://www.w3.org/2000/svg"
      class="icons-v2"
      viewBox="0 0 100 100"
      :width="width"
      :height="height"
    >
      <g id="Layer_1-2">
        <g id="Ellipse_8-2">
          <circle class="st0" cx="50" cy="50" r="50" />
          <circle class="cls-3" cx="50" cy="50" r="47" />
        </g>
        <g>
          <g>
            <path
              class="cls-2"
              d="M49.85,46.22v-5.4c0-.51-.19-.99-.51-1.39s-.8-.73-1.37-.93l-2.44-.85-2.44-.85-2.83,
                3.58-2.83,3.58-2.83-3.58-2.83-3.58-2.44,.85-2.44,.85c-.57,.2-1.04,.53-1.37,.93s-.51,
                .89-.51,1.39v6.89c0,.7,.35,1.33,.91,1.78s1.33,.74,2.19,.74h9.33"
            />
            <path
              class="cls-2"
              d="M42.6,30.18c0-1.43-.58-2.72-1.52-3.66s-2.23-1.52-3.66-1.52-2.72,.58-3.66,1.52-1.52,
              2.23-1.52,3.66,.58,2.72,1.52,3.66,2.23,1.52,3.66,1.52,2.72-.58,3.66-1.52,1.52-2.23,
              1.52-3.66Z"
            />
          </g>
          <g>
            <path
              class="cls-2"
              d="M68.57,45.23v-11.21c0-3.24-2.64-5.86-5.9-5.86h-11.29s3.18,3.16,3.18,3.16"
            />
            <line class="cls-2" x1="54.56" y1="25" x2="51.38" y2="28.16" />
          </g>
          <g>
            <path
              class="cls-2"
              d="M25.07,53.06v11.21c0,3.24,2.64,5.86,5.9,5.86h11.29l-3.18-3.16"
            />
            <line class="cls-2" x1="39.08" y1="73.29" x2="42.26" y2="70.13" />
          </g>
          <g>
            <g>
              <path
                d="M61.71,66.18c-.13,0-.26-.05-.36-.15-.2-.2-.2-.52,0-.72l1.44-1.43c.2-.2,.53-.2,
                  .73,0,.2,.2,.2,.52,0,.72l-1.44,1.43c-.1,.1-.23,.15-.36,.15Z"
              />
              <path
                d="M66.45,66.18c-.13,0-.26-.05-.36-.15-.2-.2-.2-.52,0-.72l1.44-1.43c.2-.2,.53-.2,
                  .73,0s.2,.52,0,.72l-1.44,1.43c-.1,.1-.23,.15-.36,.15Z"
              />
            </g>
            <g>
              <path
                class="cls-2"
                d="M66.63,52.1h6.95v2.65c0,1.91-1.55,3.46-3.46,3.46h-.03c-1.91,
                  0-3.46-1.55-3.46-3.46v-2.65h0Z"
              />
              <path
                class="cls-2"
                d="M59.67,52.1h6.95v2.65c0,1.91-1.55,3.46-3.46,3.46h-.03c-1.91,
                  0-3.46-1.55-3.46-3.46v-2.65h0Z"
              />
              <path
                class="cls-2"
                d="M52.72,52.1h6.95v2.65c0,1.91-1.55,3.46-3.46,3.46h-.03c-1.91,
                  0-3.46-1.55-3.46-3.46v-2.65h0Z"
              />
              <path
                class="cls-2"
                d="M45.76,52.1h6.95v2.65c0,1.91-1.55,3.46-3.46,3.46h-.03c-1.91,
                  0-3.46-1.55-3.46-3.46v-2.65h0Z"
              />
            </g>
            <rect class="cls-2" x="44.34" y="49.2" width="30.66" height="2.89" />
            <rect class="cls-2" x="45.76" y="52.1" width="27.83" height="22.9" />
            <rect class="cls-2" x="48.4" y="61.53" width="7.49" height="13.47" />
            <rect class="cls-2" x="58.69" y="61.53" width="12.03" height="7.5" />
            <line class="cls-2" x1="61.69" y1="65.71" x2="63.16" y2="64.25" />
            <line class="cls-2" x1="66.44" y1="65.71" x2="67.9" y2="64.25" />
          </g>
        </g>
      </g>
    </svg>
    <svg
      v-if="type === 'supplies' && iconStatus !== 'icon-completed'"
      id="supplies"
      xmlns="http://www.w3.org/2000/svg"
      class="icons-v2"
      viewBox="0 0 100 100"
      :width="width"
      :height="height"
    >
      <g id="Layer_1-2">
        <g id="Ellipse_5-2">
          <circle class="cls-1" cx="50" cy="50" r="50" />
          <circle class="cls-3" cx="50" cy="50" r="47" />
        </g>
        <g>
          <g>
            <rect
              class="cls-2"
              x="65.08"
              y="61.12"
              width="7.16"
              height="6.36"
              transform="translate(137.32 128.6) rotate(-180)"
            />
            <line class="cls-2" x1="62.54" y1="61.12" x2="49.44" y2="61.12" />
            <line class="cls-2" x1="62.54" y1="64.3" x2="49.44" y2="64.3" />
            <line class="cls-2" x1="62.54" y1="67.48" x2="49.44" y2="67.48" />
          </g>
          <g>
            <rect class="cls-2" x="61.31" y="41.69" width="4.54" height="2.91" />
            <path
              class="cls-2"
              d="M67.86,31.09c0-2.55-2.43-4.56-5.2-4.01-1.69,.34-3.03,1.7-3.3,3.34-.19,1.15,.12,
                2.23,.76,3.06,.74,.98,1.19,2.13,1.19,3.34v4.85h4.54v-4.85c0-1.23,.48-2.39,1.22-3.4,
                .49-.66,.78-1.47,.78-2.35Z"
            />
            <path
              class="cls-2"
              d="M58.54,44.59h10.08c1.33,0,2.41,1.08,2.41,2.41v1.29h-14.9v-1.29c0-1.33,1.08-2.41,
                2.41-2.41Z"
            />
            <rect class="cls-2" x="53.97" y="48.28" width="19.22" height="3.37" />
          </g>
          <rect class="cls-2" x="28.4" y="40.21" width="19.59" height="10.92" rx="1.33" ry="1.33" />
          <g>
            <path
              class="cls-2"
              d="M46.68,71.47v-14.35c0-.98,.8-1.78,1.78-1.78h.06v-1.72h.77c1.16,0,2.11-.9,
                2.11-2.02v-15.34c0-1.12-.94-2.02-2.11-2.02h-1.3v2.71H28.4v-2.71h-1.3c-1.16,0-2.11,
                .9-2.11,2.02v15.34c0,1.12,.94,2.02,2.11,2.02h.77v19.36c0,1.12,.94,2.02,2.11,
                2.02h16.44c1.07,0,1.94-.76,2.08-1.75h-.03c-.98,0-1.78-.8-1.78-1.78Z"
            />
            <path
              class="cls-2"
              d="M73.22,55.35h-24.76c-.98,0-1.78,.8-1.78,1.78v14.35c0,.98,.8,1.78,1.78,
                1.78h24.76c.98,0,1.78-.8,1.78-1.78v-14.35c0-.98-.8-1.78-1.78-1.78Z"
            />
            <path
              class="cls-2"
              d="M47.99,36.96v-11.67l-2.51,2.19-2.59-2.49-2.35,2.25-2.21-2.12-2.39,
                2.3-2.53-2.43-2.66,2.55-2.35-2.25v11.67h19.59Zm-3.65-6.37h0Zm0,3.34h0Z"
            />
          </g>
          <g>
            <rect id="Rectangle_856" class="cls-4" x="30.73" y="55.88" width="2.6" height="2.2" />
            <rect id="Rectangle_856-2" class="cls-4" x="34.72" y="55.88" width="2.6" height="2.2" />
            <rect id="Rectangle_856-3" class="cls-4" x="38.71" y="55.88" width="2.6" height="2.2" />
            <rect id="Rectangle_856-4" class="cls-4" x="42.71" y="55.88" width="2.6" height="2.2" />
          </g>
          <g>
            <rect id="Rectangle_856-5" class="cls-4" x="30.73" y="60.08" width="2.6" height="2.2" />
            <rect id="Rectangle_856-6" class="cls-4" x="34.72" y="60.08" width="2.6" height="2.2" />
            <rect id="Rectangle_856-7" class="cls-4" x="38.71" y="60.08" width="2.6" height="2.2" />
            <rect id="Rectangle_856-8" class="cls-4" x="42.71" y="60.08" width="2.6" height="2.2" />
          </g>
          <g>
            <rect id="Rectangle_856-9" class="cls-4" x="30.73" y="64.28" width="2.6" height="2.2" />
            <rect id="Rectangle_856-10" class="cls-4" x="34.72" y="64.28" width="2.6" height="2.2" />
            <rect id="Rectangle_856-11" class="cls-4" x="38.71" y="64.28" width="2.6" height="2.2" />
            <rect id="Rectangle_856-12" class="cls-4" x="42.71" y="64.28" width="2.6" height="2.2" />
          </g>
          <g>
            <rect id="Rectangle_856-13" class="cls-4" x="30.73" y="68.48" width="2.6" height="2.2" />
            <rect id="Rectangle_856-14" class="cls-4" x="34.72" y="68.48" width="2.6" height="2.2" />
            <rect id="Rectangle_856-15" class="cls-4" x="38.71" y="68.48" width="2.6" height="2.2" />
            <rect id="Rectangle_856-16" class="cls-4" x="42.71" y="68.48" width="2.6" height="2.2" />
          </g>
        </g>
      </g>
    </svg>
    <svg
      v-if="type === 'market_business' && iconStatus !== 'icon-completed'"
      id="market_business"
      xmlns="http://www.w3.org/2000/svg"
      class="icons-v2"
      viewBox="0 0 100 100"
      :width="width"
      :height="height"
    >
      <g id="Layer_1-2">
        <g id="Ellipse_5-2">
          <circle class="cls-1" cx="50" cy="50" r="50" />
          <circle class="cls-3" cx="50" cy="50" r="47" />
        </g>
        <g>
          <g>
            <path
              class="cls-2"
              d="M55.34,25H26.87c-1.03,0-1.87,.72-1.87,1.61v14.83c0,.89,.84,1.61,1.87,
                1.61h8.58l5.65,4.85,5.65-4.85h8.58c1.03,0,1.87-.72,
                1.87-1.61v-14.83c0-.89-.84-1.61-1.87-1.61Z"
            />
            <path
              class="cls-2"
              d="M46.01,30.23c-.55-.57-1.3-.93-2.14-.93s-1.59,.35-2.14,.93c-.26,.28-.48,.6-.63,
                .97-.15-.36-.36-.69-.63-.97-.55-.57-1.3-.93-2.14-.93s-1.59,.35-2.14,.93-.89,
                1.36-.89,2.23,.34,1.66,.89,2.23l4.79,5.01,5.02-5.01c.55-.57,.89-1.36,
                .89-2.23s-.34-1.66-.89-2.23Z"
            />
          </g>
          <g>
            <polygon
              class="cls-2"
              points="45.27 51.36 45.27 57.73 45.27 64.09 68.74 70.45 68.74 57.73 68.74 45 45.27 51.36"
            />
            <polygon
              class="cls-2"
              points="68.74 45 68.74 57.73 68.74 70.45 75 74.46 75 57.73 75 41 68.74 45"
            />
            <path
              class="cls-2"
              d="M31.68,51.29h13.59v12.88h-13.59c-1.82,0-3.29-1.47-3.29-3.29v-6.3c0-1.82,1.47-3.29,3.29-3.29Z"
            />
            <g>
              <line class="cls-2" x1="32.55" y1="54.66" x2="32.55" y2="60.79" />
              <line class="cls-2" x1="36.83" y1="54.66" x2="36.83" y2="60.79" />
              <line class="cls-2" x1="41.11" y1="54.66" x2="41.11" y2="60.79" />
            </g>
            <path
              class="cls-2"
              d="M38.83,64.17h6.44v10.83h-2.74c-2.04,0-3.7-1.66-3.7-3.7v-7.13h0Z"
            />
          </g>
        </g>
      </g>
    </svg>
    <svg
      v-if="type === 'customer_payments' && iconStatus !== 'icon-completed'"
      id="customer_payments"
      xmlns="http://www.w3.org/2000/svg"
      class="icons-v2"
      viewBox="0 0 100 100"
      :width="width"
      :height="height"
    >
      <g id="Layer_1-2">
        <g id="Ellipse_6-2">
          <circle class="st0" cx="50" cy="50" r="50" />
          <circle class="cls-3" cx="50" cy="50" r="47" />
        </g>
        <g>
          <path
            class="cls-2"
            d="M40.85,27.98h29.68c1.2,0,2.17,.9,2.17,2v23.73H27.3V29.98c0-1.1,.97-2,2.17-2h3.38"
          />
          <path
            class="cls-2"
            d="M55.34,65.07H26.79c-.99,0-1.79-.93-1.79-2.08v-7.19c0-1.15,.8-2.08,
              1.79-2.08h46.43c.99,0,1.79,.93,1.79,2.08v7.19c0,1.15-.8,2.08-1.79,2.08h-6.79"
          />
          <g>
            <line class="cls-2" x1="53.26" y1="56.38" x2="68.49" y2="56.38" />
            <rect class="cls-2" x="55.42" y="56.38" width="10.92" height="18.62" />
          </g>
          <g>
            <line class="cls-2" x1="42.28" y1="36.36" x2="31.37" y2="36.36" />
            <rect
              class="cls-2"
              x="32.9"
              y="25"
              width="7.82"
              height="11.33"
              transform="translate(73.63 61.33) rotate(180)"
            />
            <g>
              <line class="cls-2" x1="35.5" y1="28.09" x2="38.13" y2="28.09" />
              <line class="cls-2" x1="35.5" y1="30.67" x2="38.13" y2="30.67" />
              <line class="cls-2" x1="35.5" y1="33.24" x2="38.13" y2="33.24" />
            </g>
          </g>
          <rect class="cls-2" x="31.85" y="39.6" width="11.74" height="3.72" />
          <rect class="cls-2" x="31.85" y="46.61" width="11.74" height="3.72" />
          <g>
            <rect class="cls-2" x="47.47" y="39.6" width="3.98" height="3.72" />
            <rect class="cls-2" x="47.47" y="46.61" width="3.98" height="3.72" />
          </g>
          <g>
            <rect class="cls-2" x="55.99" y="39.6" width="3.98" height="3.72" />
            <rect class="cls-2" x="55.99" y="46.61" width="3.98" height="3.72" />
          </g>
          <g>
            <rect class="cls-2" x="64.51" y="39.6" width="3.98" height="3.72" />
            <rect class="cls-2" x="64.51" y="46.61" width="3.98" height="3.72" />
          </g>
          <rect class="cls-2" x="47.47" y="31.97" width="21.02" height="4.4" />
          <g>
            <g>
              <path
                class="cls-2"
                d="M63.33,63.3c0-.66-.27-1.26-.72-1.69s-1.06-.7-1.73-.7-1.29,.27-1.73,.7-.72,
                  1.03-.72,1.69,.27,1.26,.72,1.69,1.06,.7,1.73,.7"
              />
              <path
                class="cls-2"
                d="M58.42,68.08c0,.66,.27,1.26,.72,1.69s1.06,.7,1.73,.7,1.29-.27,1.73-.7,.72-1.03,
                  .72-1.69-.27-1.26-.72-1.69-1.06-.7-1.73-.7"
              />
            </g>
            <polyline
              class="cls-2"
              points="60.87 59.84 60.87 65.69 60.87 71.54"
            />
          </g>
        </g>
      </g>
    </svg>
    <svg
      v-if="type === 'hire_first_employee' && iconStatus !== 'icon-completed'"
      id="hire_first_employee"
      xmlns="http://www.w3.org/2000/svg"
      class="icons-v2"
      viewBox="0 0 100 100"
      :width="width"
      :height="height"
    >
      <g id="Layer_1-2">
        <g id="Ellipse_7-2">
          <circle class="cls-1" cx="50" cy="50" r="50" />
          <circle class="cls-3" cx="50" cy="50" r="47" />
        </g>
        <g>
          <path
            class="cls-1"
            d="M53.84,56.92c.18,.1,.36,.18,.55,.25-.19-.07-.37-.15-.55-.25-.52-.29-.98-.69-1.36-1.17,
              .38,.49,.84,.89,1.36,1.17Z"
          />
          <path
            class="cls-1"
            d="M52.62,35.56c.61,.64,1.15,1.39,1.6,2.21-.45-.82-.98-1.57-1.6-2.21Z"
          />
          <path
            class="cls-1"
            d="M50.57,33.96c.74,.42,1.43,.96,2.04,1.6-.61-.64-1.3-1.19-2.04-1.6Z"
          />
          <path
            class="cls-2"
            d="M44.11,38.22c1.39,1.73,3.45,2.84,5.75,2.84,1.89,0,
              3.62-.75,4.96-1.99-.18-.45-.38-.88-.6-1.29-.45-.82-.98-1.57-1.6-2.21s-1.3-1.19-2.04-1.6c-1.11-.63-2.36-.98-3.67-.98-.32,
              0-.64,.02-.95,.06-1.29,.17-2.5,.69-3.56,1.46-1.96,1.43-3.42,3.77-3.98,6.54,2.27-.02,
              4.3-1.11,5.69-2.83Z"
          />
          <path
            class="cls-2"
            d="M60.11,59.24c-1.19-1.13-2.72-1.81-4.38-1.81h-.01c-.47,0-.91-.1-1.33-.26l-2.21,
              5.73-5.27-2.36-5.27,2.36-2.21-5.73h0c-.42,.16-.86,.26-1.33,.26h-.01c-3.82,0-6.92,
              3.59-6.92,8.02v2.34c1.35,1.51,4.58,3.89,4.58,3.89,2.46,1.57,5.23,2.64,8.19,3.07,.98,
              .14,1.96,.24,2.97,.24s2-.1,2.97-.24c2.56-.38,4.96-1.25,
              7.15-2.49-1.26-1.44-2.04-3.34-2.04-5.44,0-3.49,2.13-6.45,5.11-7.59Zm-24.31,12.44v0Z"
          />
          <path
            class="cls-2"
            d="M41.63,62.9l5.27-2.36-7.1-3.18,.17-.44c-.18,.1-.36,.18-.55,.25l2.21,5.73Z"
          />
          <path
            class="cls-2"
            d="M54.01,57.35l-7.1,3.18,5.27,2.36,2.21-5.73c-.19-.07-.37-.15-.55-.25l.17,.44Z"
          />
          <path
            class="cls-2"
            d="M46.91,60.53l7.1-3.18-.17-.44c-.52-.29-.98-.69-1.36-1.17-.64-.81-1.05-1.86-1.13-3.02-1.3,.94-2.82,
              1.48-4.44,1.48s-3.14-.55-4.44-1.48c-.13,1.86-1.11,3.42-2.49,4.19l-.17,.44,7.1,3.18Z"
          />
          <path
            class="cls-2"
            d="M51.35,52.73c.23-.17,.45-.36,.66-.55,1.13-.99,2.04-2.28,2.68-3.79,.61-1.44,.96-3.06,
              .96-4.79,0-1.62-.31-3.15-.84-4.53-1.33,1.23-3.06,1.99-4.96,1.99-2.3,
              0-4.35-1.1-5.75-2.84-1.38,1.72-3.41,2.81-5.69,2.83-.17,.82-.26,1.67-.26,2.54,0,.43,
              .02,.86,.07,1.28,.13,1.25,.44,2.44,.9,3.52,.32,.76,.71,1.46,1.16,2.09,.45,.64,.96,
              1.21,1.52,1.7,.22,.19,.43,.38,.66,.55,1.3,.94,2.82,1.48,4.44,1.48s3.14-.55,4.44-1.48Z"
          />
          <path
            class="cls-2"
            d="M60.26,53.44h-3.54l3.54-4.28v-16.42c-3.7-3.07-8.32-4.92-13.36-4.92-12.1,0-21.91,
              10.56-21.91,23.59,0,6.37,2.35,12.14,6.16,16.38v-2.34c0-4.43,3.1-8.02,
              6.92-8.02h.01c.47,0,.91-.1,1.33-.26,.19-.07,.37-.15,.55-.25,1.38-.77,
              2.36-2.33,2.49-4.19-.23-.17-.45-.36-.66-.55-.56-.49-1.07-1.06-1.52-1.7-.45-.64-.84-1.34-1.16-2.09-.46-1.08-.77-2.26-.9-3.52-.04-.42-.07-.84-.07-1.28,0-.88,.1-1.73,
              .26-2.54,.56-2.77,2.02-5.11,3.98-6.54,1.06-.78,2.26-1.29,3.56-1.46,
              .31-.04,.63-.06,.95-.06,1.31,0,2.55,.35,3.67,.98,.74,.42,1.43,.96,2.04,1.6s1.15,1.39,
              1.6,2.21c.22,.41,.42,.84,.6,1.29,.54,1.37,.84,2.91,.84,4.53,0,1.73-.36,3.35-.96,
              4.79-.64,1.51-1.55,2.81-2.68,3.79-.22,.19-.43,.38-.66,.55,.08,1.16,.49,2.21,1.13,3.02,
              .38,.49,.84,.89,1.36,1.17,.18,.1,.36,.18,.55,.25,.42,.16,.86,.26,1.33,.26h.01c1.66,0,
              3.19,.68,4.38,1.81,.83-.32,1.73-.5,2.67-.5,1.65,0,3.17,.53,4.43,1.44,.79-2.12,
              1.32-4.38,1.51-6.75h-8.46Zm-28.27-4.08v0h0v0h0Z"
          />
          <path
            class="cls-2"
            d="M39.42,57.17h0c-.42,.16-.86,.26-1.33,.26,.47,0,.91-.1,1.33-.26h0Z"
          />
          <path
            class="cls-2"
            d="M39.97,56.92c-.18,.1-.36,.18-.55,.25h0c.19-.07,.37-.15,.55-.25Z"
          />
          <path
            class="cls-2"
            d="M38.42,41.05c-.17,.82-.26,1.67-.26,2.54,0,.43,.02,.86,.07,
              1.28-.04-.42-.07-.84-.07-1.28,0-.88,.1-1.73,.26-2.54Z"
          />
          <path
            class="cls-2"
            d="M40.28,50.48c.45,.64,.96,1.21,1.52,1.7-.56-.49-1.07-1.06-1.52-1.7Z"
          />
          <path
            class="cls-2"
            d="M56.72,53.44h18.28V25h-14.74v24.15l-3.54,4.28Zm12.97-24.35h0Zm1.84,3.12h0Zm0,
              3.43h0Zm-6.36,0h0Zm6.36,3.43h0Zm0,3.43h0Zm0,3.43h0Zm0,3.43h0Z"
          />
          <path
            class="cls-2"
            d="M67.21,60.18c-1.26-.91-2.78-1.44-4.43-1.44-.94,0-1.84,.18-2.67,.5-2.98,1.13-5.11,
              4.1-5.11,7.59,0,2.1,.78,4,2.04,5.44,1.42,1.62,3.47,2.65,5.75,2.65,4.3,0,7.78-3.62,
              7.78-8.09,0-2.76-1.33-5.19-3.36-6.65Z"
          />
          <path id="Path_26" class="cls-2" d="M58.99,66.83l3.09,3.68,4.39-7.46" />
        </g>
      </g>
    </svg>
    <svg
      v-if="type === 'set_up_payroll' && iconStatus !== 'icon-completed'"
      id="set_up_payroll" class="icons-v2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      :width="width"
      :height="height"
    >
      <g id="Layer_1-2">
        <g id="Ellipse_6-2">
          <circle class="cls-1" cx="50" cy="50" r="50" />
          <circle class="cls-3" cx="50" cy="50" r="47" />
        </g>
        <g>
          <g>
            <g>
              <path
                class="cls-2"
                d="M66.96,59.05c0-1.01-.37-1.93-.96-2.6s-1.41-1.07-2.31-1.07-1.72,.41-2.31,1.07-.96,
                  1.58-.96,2.6,.37,1.93,.96,2.6,1.41,1.07,2.31,1.07"
              />
              <path
                class="cls-2"
                d="M60.41,66.39c0,1.01,.37,1.93,.96,2.6s1.41,1.07,2.31,1.07,1.72-.41,2.31-1.07,
                  .96-1.58,.96-2.6-.37-1.93-.96-2.6-1.41-1.07-2.31-1.07"
              />
            </g>
            <polyline
              class="cls-2"
              points="63.68 53.76 63.68 62.72 63.68 71.68"
            />
          </g>
          <g>
            <path
              class="cls-2"
              d="M52.37,62.72c0-2.32,.6-4.49,1.64-6.34h-3.51c-.65,0-1.19-.58-1.19-1.29v-3.97c0-.71,
                .53-1.29,1.19-1.29h3.87c.65,0,1.19,.58,1.19,1.29v3.07c2.06-2.31,4.94-3.76,8.14-3.76,
                2.44,0,4.7,.85,6.55,2.28v-18.19c0-2.64-1.97-4.79-4.41-4.79h-2.83v2.41c0,1.29-.96,
                2.34-2.15,2.34s-2.15-1.05-2.15-2.34v-2.41h-4.41v2.41c0,1.29-.96,2.34-2.15,
                2.34s-2.15-1.05-2.15-2.34v-2.41h-4.41v2.41c0,1.29-.96,2.34-2.15,
                2.34s-2.15-1.05-2.15-2.34v-2.41h-4.41v2.41c0,1.29-.96,2.34-2.15,
                2.34s-2.15-1.05-2.15-2.34v-2.41h-3.13c-2.43,0-4.41,2.14-4.41,4.79v32.36c0,2.64,1.97,
                4.79,4.41,4.79h26.55c-2.21-2.24-3.59-5.42-3.59-8.96Zm6.55-21.61c0-.71,.53-1.29,
                1.19-1.29h3.87c.65,0,1.19,.58,1.19,1.29v3.97c0,.71-.53,1.29-1.19,1.29h-3.87c-.65,
                0-1.19-.58-1.19-1.29v-3.97Zm-9.62,0c0-.71,.53-1.29,1.19-1.29h3.87c.65,0,1.19,.58,
                1.19,1.29v3.97c0,.71-.53,1.29-1.19,1.29h-3.87c-.65,
                0-1.19-.58-1.19-1.29v-3.97Zm-12.99,24c0,.71-.53,1.29-1.19,1.29h-3.87c-.65,
                0-1.19-.58-1.19-1.29v-3.97c0-.71,.53-1.29,1.19-1.29h3.87c.65,0,1.19,.58,1.19,
                1.29v3.97Zm0-10.02c0,.71-.53,1.29-1.19,1.29h-3.87c-.65,
                0-1.19-.58-1.19-1.29v-3.97c0-.71,.53-1.29,1.19-1.29h3.87c.65,0,1.19,.58,1.19,
                1.29v3.97Zm9.62,10.02c0,.71-.53,1.29-1.19,1.29h-3.87c-.65,
                0-1.19-.58-1.19-1.29v-3.97c0-.71,.53-1.29,1.19-1.29h3.87c.65,0,1.19,.58,
                1.19,1.29v3.97Zm0-10.02c0,.71-.53,1.29-1.19,1.29h-3.87c-.65,
                0-1.19-.58-1.19-1.29v-3.97c0-.71,.53-1.29,1.19-1.29h3.87c.65,0,1.19,.58,1.19,
                1.29v3.97Zm0-10.02c0,.71-.53,1.29-1.19,1.29h-3.87c-.65,
                0-1.19-.58-1.19-1.29v-3.97c0-.71,.53-1.29,1.19-1.29h3.87c.65,0,1.19,.58,1.19,
                1.29v3.97Z"
            />
            <path
              class="cls-2"
              d="M44.75,39.82h-3.87c-.65,0-1.19,.58-1.19,1.29v3.97c0,.71,.53,1.29,1.19,1.29h3.87c.65,
                0,1.19-.58,1.19-1.29v-3.97c0-.71-.53-1.29-1.19-1.29Z"
            />
            <path
              class="cls-2"
              d="M50.49,46.36h3.87c.65,0,1.19-.58,1.19-1.29v-3.97c0-.71-.53-1.29-1.19-1.29h-3.87c-.65,
                0-1.19,.58-1.19,1.29v3.97c0,.71,.53,1.29,1.19,1.29Z"
            />
            <path
              class="cls-2"
              d="M60.11,46.36h3.87c.65,0,1.19-.58,1.19-1.29v-3.97c0-.71-.53-1.29-1.19-1.29h-3.87c-.65,
                0-1.19,.58-1.19,1.29v3.97c0,.71,.53,1.29,1.19,1.29Z"
            />
            <path
              class="cls-2"
              d="M35.13,59.85h-3.87c-.65,0-1.19,.58-1.19,1.29v3.97c0,.71,.53,1.29,1.19,
                1.29h3.87c.65,0,1.19-.58,1.19-1.29v-3.97c0-.71-.53-1.29-1.19-1.29Z"
            />
            <path
              class="cls-2"
              d="M44.75,59.85h-3.87c-.65,0-1.19,.58-1.19,1.29v3.97c0,.71,.53,1.29,1.19,
                1.29h3.87c.65,0,1.19-.58,1.19-1.29v-3.97c0-.71-.53-1.29-1.19-1.29Z"
            />
            <path
              class="cls-2"
              d="M35.13,49.83h-3.87c-.65,0-1.19,.58-1.19,1.29v3.97c0,.71,.53,1.29,1.19,
                1.29h3.87c.65,0,1.19-.58,1.19-1.29v-3.97c0-.71-.53-1.29-1.19-1.29Z"
            />
            <path
              class="cls-2"
              d="M44.75,49.83h-3.87c-.65,0-1.19,.58-1.19,1.29v3.97c0,.71,.53,1.29,1.19,
                1.29h3.87c.65,0,1.19-.58,1.19-1.29v-3.97c0-.71-.53-1.29-1.19-1.29Z"
            />
            <path
              class="cls-2"
              d="M54.36,49.83h-3.87c-.65,0-1.19,.58-1.19,1.29v3.97c0,.71,.53,1.29,1.19,
                1.29h3.51c.44-.79,.96-1.53,1.54-2.19v-3.07c0-.71-.53-1.29-1.19-1.29Z"
            />
            <path
              class="cls-2"
              d="M70.24,52.72c-1.85-1.43-4.11-2.28-6.55-2.28-3.2,0-6.08,1.44-8.14,3.76-.59,.66-1.1,
                1.4-1.54,2.19-1.03,1.85-1.64,4.02-1.64,6.34,0,3.54,1.39,6.72,3.59,8.96,2.02,2.05,
                4.73,3.32,7.72,3.32,6.25,0,11.32-5.5,11.32-12.28,0-4.13-1.88-7.78-4.76-10Z"
            />
            <path
              class="cls-2"
              d="M34.69,25c-1.19,0-2.15,1.05-2.15,2.34v4.81c0,1.29,.96,2.34,2.15,2.34s2.15-1.05,
                2.15-2.34v-4.81c0-1.29-.96-2.34-2.15-2.34Z"
            />
            <path
              class="cls-2"
              d="M43.41,25c-1.19,0-2.15,1.05-2.15,2.34v4.81c0,1.29,.96,2.34,2.15,2.34s2.15-1.05,
                2.15-2.34v-4.81c0-1.29-.96-2.34-2.15-2.34Z"
            />
            <path
              class="cls-2"
              d="M52.13,25c-1.19,0-2.15,1.05-2.15,2.34v4.81c0,1.29,.96,2.34,2.15,2.34s2.15-1.05,
                2.15-2.34v-4.81c0-1.29-.96-2.34-2.15-2.34Z"
            />
            <path
              class="cls-2"
              d="M60.85,25c-1.19,0-2.15,1.05-2.15,2.34v4.81c0,1.29,.96,2.34,2.15,2.34s2.15-1.05,
                2.15-2.34v-4.81c0-1.29-.96-2.34-2.15-2.34Z"
            />
          </g>
        </g>
      </g>
    </svg>
    <svg
      v-if="type === 'find_tax_advisor' && iconStatus !== 'icon-completed'"
      id="find_tax_advisor"
      xmlns="http://www.w3.org/2000/svg"
      class="icons-v3"
      viewBox="0 0 100 100"
      :width="width"
      :height="height"
    >
      <g id="Layer_1-2">
        <g id="Ellipse_8-2">
          <circle class="cls-1" cx="50" cy="50" r="50" />
          <circle class="cls-4" cx="50" cy="50" r="47" />
        </g>
        <g>
          <g>
            <g>
              <path
                class="cls-2"
                d="M50.28,37.16c-.31-.53-.68-1-1.1-1.41s-.9-.76-1.41-1.02c-.77-.4-1.63-.62-2.53-.62-.22,
                  0-.44,.02-.66,.04-.89,.11-1.72,.44-2.46,.93-1.36,.91-2.36,2.41-2.75,4.17,1.57-.01,
                  2.97-.71,3.93-1.81,.52,.6,1.19,1.08,1.93,1.39,.63,.26,1.31,.42,2.04,.42,1.31,0,
                  2.5-.48,3.42-1.27-.12-.29-.26-.56-.41-.83Z"
              />
              <path
                class="cls-2"
                d="M41.59,55.18l3.64-3.51-4.91-2.03,.12-.28c-.12,.06-.25,.12-.38,.16l1.53,5.65Z"
              />
              <path
                class="cls-2"
                d="M50.7,37.99c-.92,.79-2.11,1.27-3.42,1.27-.72,
                  0-1.41-.15-2.04-.42-.75-.31-1.41-.79-1.93-1.39-.96,1.1-2.36,1.79-3.93,1.81-.11,
                  .52-.18,1.06-.18,1.62,0,.28,.02,.55,.05,.81,.09,.8,.31,1.55,.62,2.24,.22,.48,.49,
                  .93,.8,1.33,.31,.41,.66,.77,1.05,1.08,.15,.12,.3,.24,.46,.35,.9,.6,1.95,.94,3.07,
                  .94s2.17-.35,3.07-.94c.16-.11,.31-.23,.46-.35,.78-.63,1.41-1.46,1.85-2.42,.42-.92,
                  .67-1.95,.67-3.06,0-1.03-.21-2.01-.58-2.89Z"
              />
              <path
                class="cls-2"
                d="M40.06,49.53c-.15,.05-.29,.09-.45,.12,.15-.03,.3-.07,.45-.12h0Z"
              />
              <path
                class="cls-2"
                d="M40.44,49.37c-.12,.06-.25,.12-.38,.16h0c.13-.05,.26-.1,.38-.16Z"
              />
              <path
                class="cls-2"
                d="M39.37,39.25c-.11,.52-.18,1.06-.18,1.62,0,.28,.02,.55,
                  .05,.81-.03-.27-.05-.54-.05-.81,0-.56,.07-1.1,.18-1.62Z"
              />
              <path
                class="cls-2"
                d="M50.02,49.37c-.95-.49-1.63-1.49-1.72-2.67-.9,.6-1.95,.94-3.07,
                  .94s-2.17-.35-3.07-.94c-.09,1.18-.77,2.18-1.72,2.67l-.38,.16,5.17,2.14,
                  5.17-2.14-.38-.16Z"
              />
              <line class="cls-2" x1="37.52" y1="58.78" x2="37.52" y2="54.62" />
              <line class="cls-2" x1="52.94" y1="58.87" x2="52.94" y2="54.62" />
              <polygon
                class="cls-2"
                points="45.23 51.67 43.02 53.8 45.23 56.81 47.44 53.8 45.23 51.67"
              />
              <g>
                <path
                  class="cls-2"
                  d="M51.33,49.69h0c-.16,0-.32-.02-.47-.05-.15-.03-.3-.07-.45-.12l-1.53,
                    5.65-1.41-1.36-2.23-2.15-2.23,2.15-1.41,1.36-1.53-5.65c-.15,.05-.29,.09-.45,
                    .12-.15,.03-.48,.05-.48,.05-2.64,0-4.78,2.29-4.78,5.11l.11,1.78c.94,.96,3.06,
                    2.28,3.06,2.28,1.7,1,3.61,1.6,5.66,1.88l2.06-3.94,2.06,3.94c2.04-.28,3.96-.96,
                    5.66-1.96,0,0,2.23-1.34,3.17-2.3v-1.67c0-2.82-2.14-5.11-4.78-5.11Z"
                />
                <path
                  class="cls-2"
                  d="M45.23,56.81l-2.06,3.97c.67,.09,1.36,.12,2.06,.12s1.38-.03,2.06-.12l-2.06-3.97Z"
                />
              </g>
            </g>
            <path
              class="cls-2"
              d="M45.23,29.64c-8.62,0-15.6,7-15.6,15.63s6.99,15.63,15.6,15.63,15.6-7,15.6-15.63"
            />
            <path
              class="cls-3"
              d="M60.84,45.27c0-8.63-6.99-15.63-15.6-15.63s-15.6,7-15.6,15.63c0,4.45,1.86,8.47,4.84,
                11.31,.92,.88,1.94,1.64,3.06,2.28,1.71,.98,3.62,1.64,5.66,1.91,.67,.09,1.36,.13,
                2.06,.13s1.38-.05,2.06-.13c2.03-.27,3.95-.93,5.66-1.91,1.16-.66,2.22-1.46,3.17-2.39,
                2.91-2.84,4.72-6.81,4.72-11.21Z"
            />
            <g>
              <path
                class="cls-2"
                d="M73.92,68.68l-11.97-11.99c-1.4,2.05-3.17,3.83-5.21,5.25l11.95,11.97c1.44,1.45,
                  3.78,1.45,5.22,0h0c1.44-1.45,1.44-3.79,0-5.24Z"
              />
              <path
                class="cls-2"
                d="M39.61,64.75c1.78,.52,3.67,.79,5.62,.79,2.14,0,4.2-.33,6.13-.95s3.74-1.52,
                  5.38-2.65c1.02-.71,1.97-1.51,2.84-2.39s1.66-1.84,2.36-2.86c1.11-1.63,1.99-3.42,
                  2.59-5.34s.93-3.96,.93-6.08c0-5.6-2.26-10.67-5.93-14.34s-8.72-5.94-14.31-5.94-10.65,
                  2.27-14.31,5.94-5.93,8.74-5.93,14.34,2.26,10.67,5.93,14.34c.38,.38,.77,.74,1.18,1.09"
              />
            </g>
            <line class="cls-2" x1="67.21" y1="67.67" x2="59.55" y2="60" />
          </g>
          <g>
            <circle
              class="cls-2"
              cx="27.54"
              cy="64.22"
              r="2.54"
              transform="translate(-18.95 12.06) rotate(-18.5)"
            />
            <circle
              class="cls-2"
              cx="35.32"
              cy="72.4"
              r="2.54"
              transform="translate(-21.15 14.95) rotate(-18.5)"
            />
            <line class="cls-2" x1="36.6" y1="61.91" x2="26.05" y2="74.71" />
          </g>
          <g>
            <g>
              <path
                class="cls-2"
                d="M75,30.59c0-1.07-.42-2.04-1.1-2.74s-1.62-1.13-2.65-1.13-1.97,.43-2.65,1.13-1.1,
                  1.67-1.1,2.74,.42,2.04,1.1,2.74,1.62,1.13,2.65,1.13"
              />
              <path
                class="cls-2"
                d="M67.5,38.34c0,1.07,.42,2.04,1.1,2.74s1.62,1.13,2.65,1.13,1.97-.43,2.65-1.13,
                  1.1-1.67,1.1-2.74-.42-2.04-1.1-2.74-1.62-1.13-2.65-1.13"
              />
            </g>
            <polyline class="cls-2" points="71.25 25 71.25 34.46 71.25 43.92" />
          </g>
        </g>
      </g>
    </svg>
    <svg
      v-if="type === 'file_taxes' && iconStatus !== 'icon-completed'"
      id="file_taxes"
      xmlns="http://www.w3.org/2000/svg"
      class="icons-v2"
      viewBox="0 0 100 100"
      :width="width"
      :height="height"
    >
      <g id="Layer_1-2">
        <g id="Ellipse_8-2">
          <circle class="cls-1" cx="50" cy="50" r="50" />
          <circle class="cls-3" cx="50" cy="50" r="47" />
        </g>
        <g>
          <g>
            <g>
              <g>
                <path
                  class="cls-2"
                  d="M50.92,37.04c0-3.01,1.11-5.75,2.93-7.86h-7.66l-.03,.03v10.49c0,1.47-1.19,
                    2.67-2.67,2.67h-10.49v30.1c0,1.4,1.13,2.53,2.53,2.53h32.39c1.4,0,2.53-1.13,
                    2.53-2.53v-26.01c-2.06,1.64-4.66,2.62-7.49,2.62-6.65,0-12.04-5.39-12.04-12.04Z"
                />
                <path
                  class="cls-2"
                  d="M62.96,25c-3.64,0-6.9,1.62-9.11,4.18-1.82,2.11-2.93,4.85-2.93,7.86,0,6.65,5.39,
                    12.04,12.04,12.04,2.84,0,5.44-.99,7.49-2.62,2.77-2.21,4.55-5.6,4.55-9.42,
                    0-6.65-5.39-12.04-12.04-12.04Z"
                />
              </g>
              <path
                class="cls-2"
                d="M46.16,39.71v-10.49l-13.16,13.16h0s10.49,0,10.49,0c1.47,0,2.67-1.19,2.67-2.67Z"
              />
            </g>
            <rect class="cls-2" x="37.3" y="47.28" width="13.61" height="4.21" />
            <g>
              <line class="cls-2" x1="37.17" y1="56.18" x2="44.69" y2="56.18" />
              <line class="cls-2" x1="47.84" y1="56.18" x2="55.36" y2="56.18" />
              <line class="cls-2" x1="58.76" y1="56.18" x2="66.28" y2="56.18" />
            </g>
            <g>
              <g>
                <line class="cls-2" x1="38.75" y1="60.89" x2="45.28" y2="60.89" />
                <line class="cls-2" x1="42.02" y1="60.94" x2="42.02" y2="70.7" />
              </g>
              <g>
                <line class="cls-2" x1="47.88" y1="70.7" x2="51.98" y2="60.94" />
                <line class="cls-2" x1="56.09" y1="70.7" x2="51.98" y2="60.94" />
                <line class="cls-2" x1="49.61" y1="66.57" x2="54.35" y2="66.57" />
              </g>
              <g>
                <line class="cls-2" x1="58.69" y1="60.89" x2="64.7" y2="70.7" />
                <line class="cls-2" x1="64.7" y1="60.89" x2="58.69" y2="70.7" />
              </g>
            </g>
            <g>
              <circle class="cls-2" cx="60.87" cy="32.39" r="2.31" />
              <circle class="cls-2" cx="65.22" cy="41.69" r="2.31" />
              <line class="cls-2" x1="69.35" y1="33.01" x2="56.56" y2="41" />
            </g>
          </g>
          <g>
            <line class="cls-2" x1="28.99" y1="42.37" x2="28.99" y2="49.89" />
            <line class="cls-2" x1="28.99" y1="53.04" x2="28.99" y2="60.56" />
            <line class="cls-2" x1="28.99" y1="63.95" x2="28.99" y2="71.47" />
          </g>
          <g>
            <line class="cls-2" x1="25" y1="39.98" x2="25" y2="47.5" />
            <line class="cls-2" x1="25" y1="50.64" x2="25" y2="58.16" />
            <line class="cls-2" x1="25" y1="61.56" x2="25" y2="69.08" />
          </g>
          <g id="Group_119">
            <line id="Line_39" class="cls-2" x1="27.88" y1="33.29" x2="36.22" y2="33.29" />
            <line id="Line_40" class="cls-2" x1="31.96" y1="29.21" x2="31.96" y2="37.56" />
          </g>
        </g>
      </g>
    </svg>
    <svg
      v-if="type === 'configure_domain' && iconStatus !== 'icon-completed'"
      id="configure_domain"
      xmlns="http://www.w3.org/2000/svg"
      class="icons-v2"
      viewBox="0 0 100 100"
      :width="width"
      :height="height"
    >
      <g id="Layer_3">
        <g>
          <g id="Ellipse_8-2">
            <circle class="st0" cx="50" cy="50" r="50" />
            <circle class="cls-4" cx="50" cy="50" r="47" />
          </g>
          <g>
            <path class="st3" d="M73.57,46.89l-6.37-.67c-.46-2.05-1.28-3.97-2.39-5.68l4.06-5.01c.51-.63.47-1.55-.11-2.13l-2.15-2.15c-.58-.58-1.5-.63-2.13-.11l-5.04,4.08c-1.71-1.08-3.6-1.88-5.64-2.33l-.68-6.46c-.09-.81-.77-1.43-1.59-1.43h-3.04c-.82,0-1.5.62-1.59,1.43l-.68,6.46c-2.04.45-3.93,1.25-5.64,2.33l-5.04-4.08c-.63-.51-1.55-.47-2.13.11l-2.15,2.15c-.58.58-.63,1.5-.11,2.13l4.06,5.01c-1.11,1.71-1.93,3.63-2.39,5.68l-6.37.67c-.81.09-1.43.77-1.43,1.59v3.04c0,.82.62,1.5,1.43,1.59l6.31.66c.44,2.09,1.26,4.04,2.37,5.78l-3.98,4.91c-.51.63-.47,1.55.11,2.13l2.15,2.15c.58.58,1.5.63,2.13.11l4.88-3.95c1.75,1.14,3.72,1.97,5.82,2.43l.65,6.22c.09.81.77,1.43,1.59,1.43h3.04c.82,0,1.5-.62,1.59-1.43l.65-6.22c2.11-.46,4.07-1.3,5.82-2.43l4.88,3.95c.63.51,1.55.47,2.13-.11l2.15-2.15c.58-.58.63-1.5.11-2.13l-3.98-4.91c1.11-1.75,1.93-3.69,2.37-5.78l6.31-.66c.81-.09,1.43-.77,1.43-1.59v-3.04c0-.82-.62-1.5-1.43-1.59Z"/>
            <g>
              <path class="st3" d="M43.61,50.11h-5.75c0,2.71.9,5.2,2.4,7.22,1.07-.76,2.43-1.39,4.02-1.83-.43-1.62-.67-3.45-.67-5.39Z"/>
              <path class="st3" d="M56.39,50.11h5.75c0-2.71-.9-5.2-2.4-7.22-1.07.76-2.43,1.39-4.02,1.83.43,1.62.67,3.45.67,5.39Z"/>
              <path class="st3" d="M44.28,44.73c-1.59-.45-2.95-1.08-4.02-1.83-1.5,2.02-2.4,4.51-2.4,7.22h5.75c0-1.94.25-3.76.67-5.39Z"/>
              <path class="st3" d="M56.39,50.11c0,1.94-.25,3.76-.67,5.39,1.59.45,2.95,1.08,4.02,1.83,1.5-2.02,2.4-4.51,2.4-7.22h-5.75Z"/>
              <path class="st3" d="M50,54.74c2.07,0,4.02.27,5.72.75.43-1.62.67-3.45.67-5.39h-6.39v4.63Z"/>
              <path class="st3" d="M50,45.48c-2.07,0-4.02-.27-5.72-.75-.43,1.62-.67,3.45-.67,5.39h6.39v-4.63Z"/>
              <path class="st3" d="M50,50.11h-6.39c0,1.94.25,3.76.67,5.39,1.7-.48,3.65-.75,5.72-.75v-4.63Z"/>
              <path class="st3" d="M50,45.48v4.63h6.39c0-1.94-.25-3.76-.67-5.39-1.7.48-3.65.75-5.72.75Z"/>
              <path class="st3" d="M50,37.98c-3.99,0-7.53,1.94-9.74,4.92,1.07.76,2.43,1.39,4.02,1.83,1.05-4,3.21-6.75,5.72-6.75Z"/>
              <path class="st3" d="M50,37.98c2.51,0,4.67,2.75,5.72,6.75,1.59-.45,2.95-1.08,4.02-1.83-2.21-2.98-5.74-4.92-9.74-4.92Z"/>
              <path class="st3" d="M50,37.98c-2.51,0-4.67,2.75-5.72,6.75,1.7.48,3.65.75,5.72.75v-7.5Z"/>
              <path class="st3" d="M55.72,44.73c-1.05-4-3.21-6.75-5.72-6.75v7.5c2.07,0,4.02-.27,5.72-.75Z"/>
              <path class="st3" d="M50,62.25c3.99,0,7.53-1.94,9.74-4.92-1.07-.76-2.43-1.39-4.02-1.83-1.05,4-3.21,6.75-5.72,6.75Z"/>
              <path class="st3" d="M50,62.25c-2.51,0-4.67-2.75-5.72-6.75-1.59.45-2.95,1.08-4.02,1.83,2.21,2.98,5.74,4.92,9.74,4.92Z"/>
              <path class="st3" d="M50,62.25c2.51,0,4.67-2.75,5.72-6.75-1.7-.48-3.65-.75-5.72-.75v7.5Z"/>
              <path class="st3" d="M44.28,55.5c1.05,4,3.21,6.75,5.72,6.75v-7.5c-2.07,0-4.02.27-5.72.75Z"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
    <svg
      v-if="type === 'configure_hosting' && iconStatus !== 'icon-completed'"
      id="configure_hosting"
      xmlns="http://www.w3.org/2000/svg"
      class="icons-v2"
      viewBox="0 0 100 100"
      :width="width"
      :height="height"
    >
      <g id="Layer_3">
        <g>
          <g id="Ellipse_8-2">
            <circle class="st0" cx="50" cy="50" r="50" />
            <circle class="cls-4" cx="50" cy="50" r="47" />
          </g>
          <g>
            <g>
              <path class="st3" d="M33.43,41.93c-.03.13-.08.39-.15.77,0,.05-.02.09-.02.13,0,0,0,0,0,0-.02-.15-.05-.29-.07-.42-.02-.13-.04-.23-.06-.3l-.6-2.53h-1.68l-.64,2.53-.07.39c-.02.11-.04.21-.05.31-.06-.35-.11-.65-.17-.89l-.59-2.34h-1.6l1.45,5.23.02.09h1.64l.82-3.65.41,1.78.4,1.87h1.66l1.49-5.32h-1.58l-.62,2.34Z"/>
              <path class="st3" d="M41.86,41.93c-.03.13-.08.39-.15.77,0,.05-.02.09-.02.13,0,0,0,0,0,0-.02-.15-.05-.29-.07-.42-.02-.12-.04-.22-.06-.3l-.6-2.53h-1.68l-.64,2.53-.07.39c-.02.11-.04.21-.05.31-.06-.35-.11-.65-.17-.89l-.59-2.34h-1.6l1.45,5.23.02.09h1.64l.82-3.65.41,1.78.4,1.87h1.66l1.49-5.32h-1.58l-.62,2.34Z"/>
              <path class="st3" d="M50.91,39.59l-.62,2.34c-.03.13-.08.39-.15.77,0,.05-.02.09-.02.13,0,0,0,0,0,0-.02-.15-.05-.29-.07-.42-.02-.12-.04-.22-.06-.3l-.6-2.53h-1.68l-.64,2.53-.07.39c-.02.11-.04.21-.05.31-.06-.35-.11-.65-.17-.89l-.59-2.34h-1.6l1.45,5.23.02.09h1.64l.82-3.65.41,1.78.4,1.87h1.66l1.49-5.32h-1.58Z"/>
            </g>
            <polyline class="st3" points="55.16 64.04 25 64.04 25 25 70.54 25 70.54 39.21"/>
            <line class="st3" x1="46.61" y1="29.64" x2="50.29" y2="29.64"/>
            <line class="st3" x1="54.54" y1="29.64" x2="58.22" y2="29.64"/>
            <line class="st3" x1="62.47" y1="29.64" x2="66.15" y2="29.64"/>
            <line class="st3" x1="25" y1="34.28" x2="70.54" y2="34.28"/>
            <g>
              <path class="st3" d="M61.93,45.89c-3.54,1.18-5.48,5.08-4.33,8.71s4.95,5.61,8.5,4.43c1.83-.61,3.23-1.94,4-3.58.24-.51.42-1.04.53-1.6.17-.85.19-1.75.03-2.64-.05-.3-.13-.59-.22-.88s-.2-.58-.33-.85c-.38-.82-.91-1.53-1.54-2.12-.42-.4-.9-.73-1.4-1.01-1.55-.84-3.43-1.06-5.23-.45Z"/>
              <g>
                <path class="st3" d="M66.03,72.94v-9.42c-1.32.26-2.68.26-4,.02v9.41c-.01,1.14.89,2.06,1.99,2.05h0c1.11,0,2.01-.92,2.01-2.06Z"/>
                <path class="st3" d="M74.57,55.58c.28-.99.43-2.04.43-3.12,0-1.19-.18-2.34-.52-3.41s-.82-2.08-1.44-2.99c-.38-.57-.82-1.1-1.29-1.58s-1-.93-1.55-1.31c-.88-.62-1.85-1.11-2.89-1.44s-2.15-.52-3.29-.52c-3.03,0-5.78,1.26-7.77,3.3s-3.22,4.85-3.22,7.96c0,3.11,1.23,5.92,3.22,7.96s4.74,3.3,7.77,3.3,5.78-1.26,7.77-3.3c.21-.21.4-.43.59-.66"/>
              </g>
              <path class="st3" d="M66.09,59.02c1.83-.61,3.23-1.94,4-3.58.24-.51.42-1.04.53-1.6.17-.85.19-1.75.03-2.64-.05-.3-.13-.59-.22-.88s-.2-.58-.33-.85c-.38-.82-.91-1.53-1.54-2.12-.42-.4-.9-.73-1.4-1.01-1.55-.84-3.43-1.06-5.23-.45"/>
            </g>
            <g>
              <path class="st3" d="M32.54,64.04c0-2.04.2-3.98.55-5.76-2.28-.62-4.21-1.53-5.61-2.64-1.53,2.42-2.43,5.3-2.43,8.4"/>
              <path class="st3" d="M47.12,58.28c.35,1.78.55,3.72.55,5.76"/>
              <path class="st3" d="M47.67,64.04c0-2.04-.2-3.98-.55-5.76-2.1.57-4.48.9-7.01.9s-4.92-.33-7.01-.9c-.35,1.78-.55,3.72-.55,5.76"/>
              <path class="st3" d="M49.2,51.73c-2.53-1.97-5.67-3.14-9.09-3.14,3.18,0,5.9,4.01,7.01,9.69,1.12-.3,2.16-.68,3.09-1.11"/>
              <path class="st3" d="M40.1,48.59c-5.29,0-9.94,2.81-12.63,7.05,1.4,1.11,3.33,2.02,5.61,2.64,1.12-5.68,3.84-9.69,7.01-9.69Z"/>
              <path class="st3" d="M40.1,48.59c-3.18,0-5.9,4.01-7.01,9.69,2.1.57,4.48.9,7.01.9s4.92-.33,7.01-.9c-1.12-5.68-3.84-9.69-7.01-9.69Z"/>
            </g>
            <line class="st3" x1="40.1" y1="48.59" x2="40.28" y2="64.04"/>
          </g>
        </g>
      </g>
    </svg>
    <svg
      v-if="type === 'configure_address' && iconStatus !== 'icon-completed'"
      id="configure_address"
      xmlns="http://www.w3.org/2000/svg"
      class="icons-v2"
      viewBox="0 0 100 100"
      :width="width"
      :height="height"
    >
      <g id="Layer_3">
        <g>
          <g id="Ellipse_8-2">
            <circle class="st0" cx="50" cy="50" r="50" />
            <circle class="cls-4" cx="50" cy="50" r="47" />
          </g>
          <g>
            <path class="st3" d="M68.15,43.58c0-9.1-8.13-18.58-18.15-18.58s-18.15,9.47-18.15,18.58,18.15,26.86,18.15,26.86c0,0,18.15-17.76,18.15-26.86Z"/>
            <g>
              <polygon class="st3" points="57.12 35 42.88 35 39.74 39.76 60.26 39.76 57.12 35"/>
              <g>
                <path class="st3" d="M39.74,39.76h4.11v3.5c0,.55-.45,1-1,1h-2.11c-.55,0-1-.45-1-1v-3.5h0Z"/>
                <path class="st3" d="M43.84,39.76h4.11v3.5c0,.55-.45,1-1,1h-2.11c-.55,0-1-.45-1-1v-3.5h0Z"/>
                <path class="st3" d="M47.95,39.76h4.11v3.5c0,.55-.45,1-1,1h-2.11c-.55,0-1-.45-1-1v-3.5h0Z"/>
                <path class="st3" d="M52.05,39.76h4.11v3.5c0,.55-.45,1-1,1h-2.11c-.55,0-1-.45-1-1v-3.5h0Z"/>
                <path class="st3" d="M56.16,39.76h4.11v3.5c0,.55-.45,1-1,1h-2.11c-.55,0-1-.45-1-1v-3.5h0Z"/>
              </g>
              <polyline class="st3" points="58.7 44.26 58.7 53 41.3 53 41.3 44.26"/>
              <polyline class="st3" points="47.65 53 47.65 47.86 52.35 47.86 52.35 53"/>
            </g>
            <path class="st3" d="M40.51,66.22c-9.1.68-15.51,2.32-15.51,4.22,0,2.52,11.19,4.56,25,4.56s25-2.04,25-4.56c0-1.91-6.41-3.54-15.51-4.22"/>
          </g>
        </g>
      </g>
    </svg>
    <svg
      v-if="type === 'configure_phone' && iconStatus !== 'icon-completed'"
      id="configure_phone"
      xmlns="http://www.w3.org/2000/svg"
      class="icons-v2"
      viewBox="0 0 100 100"
      :width="width"
      :height="height"
    >
      <g id="Layer_3">
        <g>
          <g id="Ellipse_8-2">
            <circle class="st0" cx="50" cy="50" r="50" />
            <circle class="cls-4" cx="50" cy="50" r="47" />
          </g>
          <g id="g7199">
            <g id="g7201">
              <g id="g7215">
                <path id="path7217" class="st3" d="M69.18,38.19c0,6.16-2.4,11.33-5.62,12.7-.55.24-1.13.36-1.72.36-4.05,0-7.34-5.85-7.34-13.06s3.28-13.06,7.34-13.06c.59,0,1.17.12,1.72.36,3.22,1.38,5.62,6.54,5.62,12.7Z"/>
              </g>
              <g id="g7219">
                <path id="path7221" class="st3" d="M52.78,28.77c2.35,2.26,5.54,3.65,9.06,3.65,1.05,0,2.08-.12,3.06-.36,2.29-.55,4.35-1.71,6-3.29"/>
              </g>
              <g id="g7223">
                <path id="path7225" class="st3" d="M52.78,47.6c2.35-2.26,5.54-3.65,9.06-3.65,1.05,0,2.08.12,3.06.36,2.29.55,4.35,1.71,6,3.29"/>
              </g>
              <g id="g7227">
                <path id="path7229" class="st3" d="M48.78,38.19h26.13"/>
              </g>
              <g id="g7231">
                <path id="path7233" class="st3" d="M50.73,31.32c2.3-3.72,6.42-6.19,11.11-6.19,1.05,0,2.08.12,3.06.36,5.74,1.38,10,6.54,10,12.7s-4.26,11.33-10,12.7c-.98.24-2.01.36-3.06.36-7.22,0-13.06-5.85-13.06-13.06,0-1.43.23-2.81.66-4.1"/>
              </g>
              <g id="g7235">
                <path id="path7237" class="st3" d="M61.84,25.12v26.13"/>
              </g>
              <g id="g7267">
                <path id="path7269" class="st3" d="M68.18,72.04l5.35-3.79c1.77-1.25,1.98-3.79.45-5.33l-7.23-7.23c-1.33-1.33-3.46-1.37-4.84-.1l-4.4,4.05c-.9.82-2.25.91-3.23.19-5.42-3.97-10.12-8.66-14.09-14.09-.72-.98-.64-2.34.19-3.23l4.05-4.4c1.27-1.38,1.22-3.51-.1-4.84l-7.23-7.23c-1.53-1.53-4.07-1.32-5.33.45l-4.68,6.6c-2.92,4.12-2.74,9.68.43,13.61,7.68,9.53,16.28,18.13,25.81,25.81,3.53,2.85,8.37,3.28,12.31,1.23"/>
              </g>
              <g id="g7271">
                <path id="path7273" class="st3" d="M30.1,28.8l11.91,11.91"/>
              </g>
              <g id="g7275">
                <path id="path7277" class="st3" d="M59.29,57.99l11.91,11.91"/>
              </g>
              <g id="g7279">
                <path id="path7281" class="st3" d="M39.49,28.44l-1.16,1.16"/>
              </g>
              <g id="g7283">
                <path id="path7285" class="st3" d="M41.9,30.85l-1.16,1.16"/>
              </g>
              <g id="g7287">
                <path id="path7289" class="st3" d="M67.2,56.25l-1.16,1.16"/>
              </g>
              <g id="g7291">
                <path id="path7293" class="st3" d="M69.61,58.66l-1.16,1.16"/>
              </g>
              <g id="g7295">
                <path id="path7297" class="st3" d="M72.02,61.08l-1.16,1.16"/>
              </g>
              <g id="g7299">
                <path id="path7301" class="st3" d="M74.43,63.49l-1.16,1.16"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
    <svg
      v-if="type === 'convert_business' && iconStatus !== 'icon-completed'"
      id="convert_business"
      xmlns="http://www.w3.org/2000/svg"
      class="icons-v2"
      viewBox="0 0 100 100"
      :width="width"
      :height="height"
    >
      <g id="Layer_3">
        <g>
          <g id="Ellipse_8-2">
            <circle class="st0" cx="50" cy="50" r="50" />
            <circle class="cls-4" cx="50" cy="50" r="47" />
          </g>
          <g>
            <g>
              <line class="st3" x1="27.9" y1="53.57" x2="27.9" y2="49.75"/>
              <polyline class="st3" points="68.59 75 27.9 75 27.9 58.25"/>
              <line class="st3" x1="68.59" y1="49.75" x2="68.59" y2="60.15"/>
              <polyline class="st3" points="32.25 75 32.25 54.38 41.3 54.38 41.3 75"/>
              <polyline class="st3" points="60.48 65.58 49.4 65.58 49.4 54.38 63.3 54.38 63.3 61.48"/>
              <g>
                <path class="st3" d="M71.18,61.01c-1.06-.59-2.28-.93-3.57-.93-4.08,0-7.39,3.34-7.39,7.46s3.31,7.46,7.39,7.46,7.39-3.34,7.39-7.46c0-1.56-.47-3.01-1.29-4.21"/>
                <g>
                  <line class="st3" x1="67.61" y1="65.15" x2="67.61" y2="69.93"/>
                  <line class="st3" x1="65.24" y1="67.54" x2="69.98" y2="67.54"/>
                </g>
              </g>
            </g>
            <g>
              <g>
                <path class="st3" d="M25,41.73h9.3v4.02c0,2.21-1.79,4-4,4h-1.3c-2.21,0-4-1.79-4-4v-4.02h0Z"/>
                <path class="st3" d="M34.3,41.73h9.3v4.02c0,2.21-1.79,4-4,4h-1.3c-2.21,0-4-1.79-4-4v-4.02h0Z"/>
                <path class="st3" d="M43.6,41.73h9.3v4.02c0,2.21-1.79,4-4,4h-1.3c-2.21,0-4-1.79-4-4v-4.02h0Z"/>
                <path class="st3" d="M52.89,41.73h9.3v4.02c0,2.21-1.79,4-4,4h-1.3c-2.21,0-4-1.79-4-4v-4.02h0Z"/>
                <path class="st3" d="M62.19,41.73h9.3v4.02c0,2.21-1.79,4-4,4h-1.3c-2.21,0-4-1.79-4-4v-4.02h0Z"/>
              </g>
              <g>
                <line class="st3" x1="34.3" y1="41.73" x2="38.41" y2="25"/>
                <line class="st3" x1="62.19" y1="41.73" x2="58.08" y2="25"/>
                <line class="st3" x1="52.89" y1="41.73" x2="51.89" y2="25"/>
                <line class="st3" x1="43.61" y1="41.73" x2="44.62" y2="25"/>
                <polyline class="st3" points="61.1 25 30.04 25 25 41.73 71.49 41.73 66.45 25 65.2 25"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'StagelineNavigationIcon',
  props: {
    type: {
      type: String,
    },
    width: {
      type: Number,
      default: 50,
    },
    height: {
      type: Number,
      default: 50,
    },
    iconStatus: {
      type: String,
    },
    requiresAction: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
$required-action: #F45151;

.stageline-navigation-icon {
  .st0 {
    fill: #FFFFFF;
  }

  .st1 {
    fill: none;
    stroke: $ct-ui-primary;
    stroke-width: 6;
  }

  .st2 {
    fill: none;
    stroke: $ct-ui-primary;
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st3 {
    fill: none;
    stroke: $ct-ui-primary;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .st4 {
    fill: #FFFFFF;
    stroke: $ct-ui-primary;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .st5 {
    fill: $ct-ui-primary;
  }

  .cls-2 {
    fill: $ct-ui-primary;
  }

  .cls-3 {
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
  }

  .cls-3,
  .cls-4 {
    fill: none;
    stroke: $ct-ui-primary;
  }

  .cls-1,
  .cls-4 {
    stroke-width: 6px;
  }

  svg.icons-v2 .cls-1,
  svg.icons-v2 .cls-2,
  svg.icons-v2 .cls-3,
  svg.required-action .cls-1,
  svg.required-action .cls-2,
  svg.required-action .cls-3 {
    fill:none;
  }

  svg.icons-v2 .cls-2,
  svg.required-action .cls-2 {
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
  }

  svg.icons-v2 .cls-2,
  svg.icons-v2 .cls-3 {
    stroke: $ct-ui-primary;
  }

  svg.icons-v2 .cls-2,
  svg.icons-v2 .cls-3 {
    stroke: $ct-ui-primary;
  }

  svg.icons-v2 .cls-3,
  svg.required-action .cls-3 {
    stroke-width: 6px;
  }

  svg.icons-v2 .cls-5 {
    fill: $ct-ui-primary;
  }

  svg.icons-v3 .cls-1,
  svg.icons-v3 .cls-2,
  svg.icons-v3 .cls-3,
  svg.icons-v3 .cls-4 {
    fill: none;
  }

  svg.icons-v3 .cls-2,
  svg.icons-v3 .cls-3 {
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
  }

  svg.icons-v3 .cls-2,
  svg.icons-v3 .cls-3,
  svg.icons-v3 .cls-4 {
    stroke: $ct-ui-primary;
  }

  svg.icons-v3 .cls-3 {
    stroke-dasharray: 0 0 4.09 4.09;
  }

  svg.icons-v3 .cls-4 {
    stroke-width: 6px;
  }

  .teals {
    fill: $ct-ui-primary;
    stroke: $ct-ui-primary-dark;
  }

  svg.required-action .cls-2,
  svg.required-action .cls-3 {
    stroke: $required-action;
  }

  svg.required-action .cls-2,
  svg.required-action .cls-3 {
    stroke: $required-action;
  }

  svg.required-action .cls-5 {
    fill: $required-action;
  }
}
</style>
